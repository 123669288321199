import React, { useState, useEffect } from 'react'
import {
  Box,
  TextField,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { Timestamp } from 'firebase/firestore'
import {
  fetchUsersCompanyInvites,
  saveNewCompanyInvite,
  deleteCompanyInvite,
} from '../../database/services/companyInvites'

const ManageCustomers = () => {
  const [companyInvites, setCompanyInvites] = useState([]) // State to manage rows of companies and main users
  const [newCompanyInvite, setNewCompanyInvite] = useState({
    email: '',
    companyName: '',
  }) // State to manage new company input
  const [openModal, setOpenModal] = useState(false) // State for modal visibility
  const [selectedCompany, setSelectedCompany] = useState(null) // State for the selected company to delete
  const [showNewCompanyFields, setShowNewCompanyFields] = useState(false) // State to control visibility of new company fields

  // Fetch companies from Firestore on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedCompaniesInvites = await fetchUsersCompanyInvites()
        console.log('Fetched companies:', fetchedCompaniesInvites) // Log fetched companies
        setCompanyInvites(fetchedCompaniesInvites)
      } catch (error) {
        console.error('Error fetching companies: ', error)
      }
    }

    fetchData()
  }, [])

  // Handle changes to new company input fields
  const handleNewCompanyChange = (e) => {
    const { name, value } = e.target
    setNewCompanyInvite((prev) => ({ ...prev, [name]: value }))
  }

  // Email validation function
  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  // Add a new company and save it to Firestore
  const handleAddRow = async () => {
    if (!showNewCompanyFields) {
      // Show the input fields if not already visible
      setShowNewCompanyFields(true)
    } else {
      // Validate email before proceeding
      if (!isValidEmail(newCompanyInvite.email)) {
        alert('Please enter a valid email address.')
        return
      }

      // Save new company to Firestore
      const newCompanyInviteData = {
        ...newCompanyInvite,
        createdAt: Timestamp.now(),
      }
      newCompanyInviteData.email = newCompanyInviteData.email.toLowerCase()
      try {
        await saveNewCompanyInvite(newCompanyInviteData)
        // Add new company to local state after successful save
        setCompanyInvites((prev) => [...prev, newCompanyInviteData])
        alert('New company invite created successfully.')
      } catch (error) {
        console.error('Error saving new company: ', error)
      }
      setNewCompanyInvite({ email: '', companyName: '' }) // Reset input fields
      setShowNewCompanyFields(false) // Hide input fields after adding
    }
  }

  // Open the delete confirmation modal
  const handleRemoveRow = (company) => {
    setSelectedCompany(company)
    setOpenModal(true)
  }

  // Confirm deletion of a company
  const handleConfirmDelete = async () => {
    const companyAgeInDays = (Timestamp.now().seconds - selectedCompany.createdAt.seconds) / (60 * 60 * 24)
    if (companyAgeInDays > 5) {
      alert(
        'Only companies under 5 days old can be deleted this way. Please contact the Fairway Tech team for support.'
      )
    } else {
      try {
        await deleteCompanyInvite(selectedCompany.email)
        setCompanyInvites(companyInvites.filter((company) => company.email !== selectedCompany.email))
        alert('Company deleted successfully.')
      } catch (error) {
        console.error('Error deleting company: ', error)
      }
    }
    setOpenModal(false)
    setSelectedCompany(null)
  }

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <h2>Create New Company Invite</h2>
        {/* Button to add a new row for a company and main user */}
        <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddRow}>
          {showNewCompanyFields ? 'Save New Company Invite' : 'Add Company Invite'}
        </Button>
      </Box>

      {/* Conditionally render input fields for adding a new company */}
      {showNewCompanyFields && (
        <Box display="flex" alignItems="center" mb={2}>
          <TextField
            label="Email of Company Creator"
            name="email"
            value={newCompanyInvite.email}
            onChange={handleNewCompanyChange}
            variant="outlined"
            sx={{ marginRight: 2, width: '45%' }}
          />
          <TextField
            label="Company Name"
            name="companyName"
            value={newCompanyInvite.companyName}
            onChange={handleNewCompanyChange}
            variant="outlined"
            sx={{ marginRight: 2, width: '45%' }}
          />
        </Box>
      )}

      {/* Table to display existing companies */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Company Name</TableCell>
              <TableCell>Email of Company Creator</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companyInvites.map((companyInvite, index) => (
              <TableRow key={companyInvite.email}>
                <TableCell>{companyInvite.companyName}</TableCell>
                <TableCell>{companyInvite.email}</TableCell>
                <TableCell>{companyInvite.createdAt?.toDate().toLocaleDateString()}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleRemoveRow(companyInvite)} color="error">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for delete confirmation */}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="delete-confirmation-title"
        aria-describedby="delete-confirmation-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="delete-confirmation-title" variant="h6" component="h2">
            Are you sure you want to delete this company?
          </Typography>
          <Typography id="delete-confirmation-description" sx={{ mt: 2 }}>
            Only companies under 5 days old can be deleted this way. Are you sure you want to delete?
          </Typography>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="outlined" onClick={() => setOpenModal(false)} sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button variant="contained" color="error" onClick={handleConfirmDelete}>
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default ManageCustomers
