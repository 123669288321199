import {collection, getDocs, query, deleteDoc, doc, updateDoc, setDoc} from 'firebase/firestore'
import { db } from '../../firebaseConfig'

export const fetchGroups = async (companyId) => {
  const groupsSnapshot = await getDocs(query(collection(db, 'customers', companyId, 'groups')))
  return groupsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
}

export const updateVehicleGroup = async (companyId, vehicleId, groupId, groupName) => {
  await updateDoc(doc(db, 'customers', companyId, 'vehicles', vehicleId), {
    groupID: groupId,
    groupName: groupName,
  })
}

export const deleteGroup = async (companyId, groupId) => {
  await deleteDoc(doc(db, 'customers', companyId, 'groups', groupId))
}

export const createGroup = async (companyId, newGroup) => {
  const newDocRef = doc(collection(db, 'customers', companyId, 'groups'))
  await setDoc(newDocRef, newGroup)
  return newDocRef
}


export const updateGroup = async (companyId, groupId, updatedGroup) => {
  await setDoc(doc(db, 'customers', companyId, 'groups', groupId), updatedGroup)
}
