import { getAuth } from 'firebase/auth'

const getIdToken = async () => {
    const auth = getAuth()
    const user = auth.currentUser
    if (user) {
        return user.getIdToken()
    }
    throw new Error('No user is signed in')
}

export const fetchWithAuth = async (url, options = {}) => {
    const token = await getIdToken()
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
    }

    const mergedHeaders = { ...headers, ...(options.headers || {}) }

    const response = await fetch(url, {
        ...options,
        headers: mergedHeaders,
        mode: 'cors',
    })

    if (!response.ok) {
        const contentType = response.headers.get('Content-Type')
        if (contentType && contentType.includes('application/problem+json')) {
            const errorData = await response.json()
            alert(errorData.detail || 'An error occurred')
            throw new Error(errorData.detail || 'An error occurred')
        } else {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
    }

    return response
}
