import { ResponsiveBar } from '@nivo/bar'
import { useColorMode } from '../theme'
import { mockBarData as data } from '../data/mockData'
import { format } from 'd3-format'

function getColorForValue(value, min, max) {
  // Define the colors as an array
  const colors = [
    'rgb(198,34,40)',
    'rgb(203,57,54)',
    'rgb(209,81,69)',
    'rgb(215,105,84)',
    'rgb(220,128,99)',
    'rgb(226,152,114)',
    'rgb(232,176,129)',
    'rgb(237,199,144)',
    'rgb(243,223,159)',
    'rgb(249,247,174)',
    'rgb(232,240,165)',
    'rgb(217,234,157)',
    'rgb(201,228,148)',
    'rgb(185,221,140)',
    'rgb(169,215,131)',
    'rgb(153,209,123)',
    'rgb(137,202,114)',
    'rgb(121,196,106)',
    'rgb(105,190,98)',
  ]

  // Ensure value is within bounds
  value = Math.min(Math.max(value, min), max)

  // Find the normalized position of the value within the range
  var normalizedValue = (value - min) / (max - min)

  // Determine the index within the color array (19 segments for 20 colors)
  var index = Math.floor(normalizedValue * (colors.length - 1))

  // Return the corresponding color
  return colors[index]
}

// Assume min and max values for the dataset
var minValue = data.reduce((min, p) => (p.y < min ? p.y : min), data[0].y)
var maxValue = data.reduce((max, p) => (p.y > max ? p.y : max), data[0].y)

const BarChart = ({ isDashboard = false }) => {
  const { theme } = useColorMode()

  return (
    <ResponsiveBar
      data={data}
      keys={['y']}
      theme={{
        // added
        axis: {
          domain: {
            line: {
              stroke: theme.palette.text.secondary,
            },
          },
          legend: {
            text: {
              fill: theme.palette.text.secondary,
            },
          },
          ticks: {
            line: {
              stroke: theme.palette.text.secondary,
              strokeWidth: 1,
            },
            text: {
              fill: theme.palette.text.secondary,
            },
          },
        },
        legends: {
          container: {
            color: theme.palette.primary[500],
          },
        },
        tooltip: {
          container: {
            color: theme.palette.primary[500],
          },
        },
      }}
      indexBy="x"
      margin={{ top: 50, right: 20, bottom: 25, left: 90 }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      valueFormat={(value) => format('>-.2s')(Math.abs(value))}
      tooltipFormat={(value) => format('>-.2s')(Math.abs(value))}
      //colors="#97e3d5"
      colors={data.map((obj) => getColorForValue(obj.y, minValue, maxValue))}
      colorBy="indexValue"
      reverse={true}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: '#38bcb2',
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: '#eed312',
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: 'fries',
          },
          id: 'dots',
        },
        {
          match: {
            id: 'sandwich',
          },
          id: 'lines',
        },
      ]}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'spend ($)',
        legendPosition: 'middle',
        legendOffset: -50,
        truncateTickAt: 0,
        format: (value) => format('>-.2s')(Math.abs(value)),
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      legends={[]}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={(e) => e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue}
    />
  )
}

export default BarChart
