import React, { useState, useEffect } from 'react'
import { Modal, Box, Typography, IconButton, Table, TableBody, TableRow, TableCell } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'

const AvaWebRunModal = ({ open, handleClose, selectedRun }) => {
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  useEffect(() => {
    if (selectedRun) {
      console.log(activeStep)
      console.log(selectedRun.iteration[activeStep])
      console.log(selectedRun.iteration[activeStep].messages)
    }
  }, [activeStep])

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    overflowY: 'auto',
  }

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 2,
  }

  const iterationHeaderStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    mt: 2,
    mb: 2,
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Box sx={headerStyle}>
          <Typography variant="h6" id="modal-modal-title">
            Ava Web Run Details
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {selectedRun && (
          <Box sx={{ mt: 2 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>{selectedRun.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>{selectedRun.status}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Web</TableCell>
                  <TableCell>{selectedRun.web_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Question</TableCell>
                  <TableCell>{selectedRun.ques}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Result</TableCell>
                  <TableCell>{selectedRun.result}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        )}

        {selectedRun && selectedRun.iteration && (
          <Box sx={{ mt: 2 }}>
            <Box sx={iterationHeaderStyle}>
              <IconButton onClick={handleBack} disabled={activeStep === 0}>
                <KeyboardArrowLeft />
              </IconButton>
              <Typography variant="h6" sx={{ mx: 2 }}>
                Iteration {activeStep + 1} / {selectedRun.iteration.length}
              </Typography>
              <IconButton onClick={handleNext} disabled={activeStep === selectedRun.iteration.length - 1}>
                <KeyboardArrowRight />
              </IconButton>
            </Box>

            {selectedRun.iteration[activeStep].messages && (
              <Box sx={{ overflow: 'auto', maxHeight: '70vh', p: 2 }}>
                <Typography variant="h6">Thought & Action</Typography>
                <Typography>
                  {Array.isArray(selectedRun.iteration[activeStep].messages[1].content)
                    ? selectedRun.iteration[activeStep].messages[1].content[0].text
                    : selectedRun.iteration[activeStep].messages[1].content}
                </Typography>
              </Box>
            )}
            <Box sx={{ overflow: 'auto', maxHeight: '70vh', p: 2 }}>
              <Typography variant="h6">Screenshot</Typography>
              {selectedRun.iteration[activeStep].iteration_screenshot && (
                <img
                  src={selectedRun.iteration[activeStep].iteration_screenshot}
                  alt="Iteration Screenshot"
                  style={{ width: '100%', height: 'auto' }}
                />
              )}
            </Box>

            <Box sx={{ overflow: 'auto', maxHeight: '70vh', p: 2 }}>
              <Typography variant="h6">Observation</Typography>
              <Typography>
                {Array.isArray(selectedRun.iteration[activeStep].messages[0].content)
                  ? selectedRun.iteration[activeStep].messages[0].content[0].text
                  : selectedRun.iteration[activeStep].messages[0].content}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  )
}

export default AvaWebRunModal
