import React, { useState, useEffect } from 'react'
import { Modal, Box, Typography, IconButton, Table, TableBody, TableRow, TableCell, TableHead } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { deepPurple, deepOrange, lightBlue, green, yellow, cyan } from '@mui/material/colors'

const AvaSMSRunModal = ({ open, handleClose, selectedRun }) => {
  const [colorMap, setColorMap] = useState({})

  const generateColorForNumber = (number) => {
    const colors = [deepPurple[500], deepOrange[500], lightBlue[500], green[500], yellow[500], cyan[500]]
    if (!colorMap[number]) {
      // Assign a new color from the available colors
      setColorMap((prevColorMap) => {
        const usedColors = new Set(Object.values(prevColorMap))
        const availableColors = colors.filter((color) => !usedColors.has(color))
        const nextColor =
          availableColors.length > 0
            ? availableColors[Math.floor(Math.random() * availableColors.length)]
            : colors[Math.floor(Math.random() * colors.length)]
        return {
          ...prevColorMap,
          [number]: nextColor,
        }
      })
    }
  }

  useEffect(() => {
    if (selectedRun && selectedRun.history) {
      selectedRun.history.forEach((item) => {
        const number = item.match(/\((.*?)\)/)[1]
        generateColorForNumber(number)
      })
    }
  }, [selectedRun])

  const parseHistoryItem = (item) => {
    const sentRegex = /^(.*?) - Message sent to (.*?)\((.*?)\): (.*)$/
    const receivedRegex = /^(.*?) - Message received from (.*?)\((.*?)\): (.*)$/

    let matches = item.match(sentRegex) || item.match(receivedRegex)
    if (matches) {
      return {
        date: matches[1],
        direction: item.includes('sent') ? 'Sent' : 'Received',
        name: matches[2],
        number: matches[3],
        message: matches[4],
      }
    }
    return null
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    overflowY: 'auto',
  }

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 2,
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Box sx={headerStyle}>
          <Typography variant="h6" id="modal-modal-title">
            Ava SMS Run Details
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {selectedRun && selectedRun.history && (
          <Box sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Contact</TableCell>
                  <TableCell>Message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedRun.history.map((item, index) => {
                  const parsedItem = parseHistoryItem(item)
                  if (!parsedItem) return null

                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="body2" color="textSecondary">
                          {parsedItem.date}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{parsedItem.direction}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" style={{ color: colorMap[parsedItem.number] }}>
                          {parsedItem.name} ({parsedItem.number})
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{parsedItem.message}</Typography>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        )}
      </Box>
    </Modal>
  )
}

export default AvaSMSRunModal
