import { useState, useEffect } from 'react' // Add useState and useEffect import
import Header from '../../components/Header'
import { Box, Tab, Typography } from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import ManageCustomers from './manageCustomers'
import FairwayAdmins from './fairwayAdmins'
import VehicleComplianceKB from './vehicleComplianceKB'
import { useUser } from '../../contexts/UserContext'
import { useNavigate, useLocation } from 'react-router-dom'

const FairwayAdmin = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useUser() // Use user context
  const [value, setValue] = useState(location.pathname.split('/')[2] || 'managecustomers')

  const handleChange = (event, newValue) => {
    setValue(newValue)
    navigate(`/superadmin/${newValue}`)
  }

  useEffect(() => {
    setValue(location.pathname.split('/')[2] || 'managecustomers')
  }, [location])

  // Check if the user is a superAdmin and email ends with @getfairway.com
  const isFairwayAdmin = user?.superAdmin && user?.email?.endsWith('@getfairway.com')

  // Redirect or restrict access if not a superAdmin
  if (!isFairwayAdmin) {
    return <Typography>You do not have access to this section.</Typography>
  }

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="FAIRWAY ADMIN" />
      </Box>
      <Box sx={{ width: '100%' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="Fairway Admin Tabs">
              <Tab label="MANAGE CUSTOMERS" value="managecustomers" />
              <Tab label="FAIRWAY ADMINS" value="fairwayadmins" />
              <Tab label="VEHICLE COMPLIANCE KB" value="vehiclecompliancekb" />
            </TabList>
          </Box>
          <TabPanel value="managecustomers">
            <ManageCustomers />
          </TabPanel>
          <TabPanel value="fairwayadmins">
            <FairwayAdmins />
          </TabPanel>
          <TabPanel value="vehiclecompliancekb">
            <VehicleComplianceKB />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  )
}

export default FairwayAdmin
