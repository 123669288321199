import React, { useState, useEffect } from 'react'
import { useColorMode } from '../../theme'
import {
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  MenuItem,
  Select,
  Divider,
  Button,
  Tooltip,
  IconButton,
  TextField,
} from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import { Grid, Alert } from '@mui/material'
import { useUser } from '../../contexts/UserContext'
import CircularProgress from '@mui/material/CircularProgress'
import FileUpload from '../global/FileUpload' // Adjust the relative path as needed
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'
import { getAgentsInformation, getPaperworkData, addCustomer, addPaperworkData } from '../../database/services/settings'

const CompanyInformation = () => {
  const { company, setCompany } = useUser()
  const [companyData, setCompanyData] = useState({})
  const [states, setStates] = useState([])
  const [paperworkData, setPaperworkData] = useState({})
  const [loading, setLoading] = useState(true)
  const [saveLoading, setSaveLoading] = useState(true)
  const [missingRequiredFields, setMissingRequiredFields] = useState([])
  const [missingDelayImpactFields, setMissingDelayImpactFields] = useState([])
  const { theme } = useColorMode()

  const paperworkDataFields = {
    companyName: {
      type: 'String',
      userFriendlyName: 'Company Legal Name',
      tooltipDescription: 'Legal name of organization',
      required: true,
    },
    federalIDNumber: {
      type: 'Integer',
      length: 9,
      userFriendlyName: 'Federal ID Number',
      tooltipDescription: "Organization's Federal ID Number, must consist of 9 digits.",
      required: true,
    },
    businessLicense: {
      type: 'PDF/IMAGE',
      required: false,
      updateFrequency: 'annually',
      userFriendlyName: 'Business License or Important Documentation',
      tooltipDescription:
        'PDF or Image of Legal Business License, or any other important documentation, must renew or update annually',
      delayImpact: true,
      url: null,
    },
    address1: {
      type: 'Address',
      userFriendlyName: 'Address line 1',
      tooltipDescription: 'Legal address of organization',
      required: true,
    },
    address2: {
      type: 'Address',
      userFriendlyName: 'Address line 2',
      required: false,
    },
    city: {
      type: 'Address',
      userFriendlyName: 'City',
      required: true,
    },
    state: {
      type: 'Address',
      userFriendlyName: 'State',
      required: true,
    },
    zipcode: {
      type: 'Address',
      userFriendlyName: 'Zipcode',
      required: true,
    },
    ownerName: {
      type: 'String',
      userFriendlyName: 'Name',
      tooltipDescription: 'Name of the person responsible for compliance',
      required: false,
    },
    ownerEmail: {
      type: 'String',
      userFriendlyName: 'Email',
      tooltipDescription: 'Email of the person responsible for compliance',
      required: false,
    },
    ownerPhone: {
      type: 'Telephone',
      userFriendlyName: 'Phone',
      tooltipDescription: 'Phone number of the person responsible for compliance',
      required: false,
    },
  }

  const paperworkDataSchema = {
    companyName: null,
    federalIDNumber: null,
    businessLicense: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    zipcode: null,
    ownerName: null,
    ownerEmail: null,
    ownerPhone: null,
  }

  // setCompanyData
  useEffect(() => {
    if (company) {
      setCompanyData(company)
    }
  }, [company])

  // Fetching the list of states
  useEffect(() => {
    const fetchStates = async () => {
      try {
        const agents = await getAgentsInformation()
        setStates(agents)
      } catch (error) {
        console.error('Error fetching states: ', error)
      }
    }
    fetchStates()
  }, [])

  // Paperwork data fetching
  useEffect(() => {
    const fetchPaperworkData = async () => {
      if (company) {
        try {
          const data = await getPaperworkData(company.id)
          setPaperworkData(data || paperworkDataSchema)
        } catch (error) {
          console.error('Error fetching paperwork data: ', error)
        } finally {
          setLoading(false) // Ensuring that loading state is set to false after fetching the paperwork data
        }
      } else {
        setLoading(false) // If no company, set loading to false immediately
      }
    }
    fetchPaperworkData()
  }, [company?.id])

  // Checking for missing fields
  useEffect(() => {
    if (!loading) {
      const missingRequired = Object.entries(paperworkDataFields).filter(
        ([field, config]) => config.required && !paperworkData[field]
      )
      const missingDelayImpact = Object.entries(paperworkDataFields).filter(
        ([field, config]) => config.delayImpact && !paperworkData[field]
      )
      setMissingRequiredFields(missingRequired)
      setMissingDelayImpactFields(missingDelayImpact)
      const updatedOnboardingStatus = {
        ...companyData.onboardingStatus,
        requiredFields: missingRequired.length === 0,
        delayImpactFields: missingDelayImpact.length === 0,
      }
      setCompanyData({
        ...companyData,
        onboardingStatus: updatedOnboardingStatus,
      })
    }
  }, [paperworkData, loading])

  // Files handlers
  const handleFileUpload = (field, { url, filename, docId }) => {
    setPaperworkData((prevPaperwork) => ({
      ...prevPaperwork,
      [field]: { url, filename, docId },
    }))
  }

  const handleFileDelete = (field) => {
    setPaperworkData((prevPaperwork) => ({
      ...prevPaperwork,
      [field]: null,
    }))
  }

  const handleCompanyDataSave = async () => {
    try {
      await addCustomer(company, companyData)
      setCompany(companyData)
    } catch (error) {
      console.error('Error saving company data: ', error)
    }
  }

  const handleChange = (event) => {
    setPaperworkData({
      ...paperworkData,
      [event.target.name]: event.target.value,
    })
    if (event.target.name === 'companyName') {
      setCompanyData({
        ...companyData,
        [event.target.name]: event.target.value,
      })
    }
    if (event.target.name === 'state') {
      setCompanyData({
        ...companyData,
        paperworkState: event.target.value,
      })
    }
  }

  const handleSave = async () => {
    try {
      setLoading(true)
      await handleCompanyDataSave()
      await addPaperworkData(company, paperworkData)
      setCompany(companyData)
      setLoading(false)
    } catch (error) {
      console.error('Error saving paperwork data: ', error)
    }
  }

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                flex: 1,
              }}
            >
              <Divider sx={{ mt: 2, mb: 2 }} textAlign="left">
                Company Information{' '}
              </Divider>
              {/* Company Legal Name */}
              <FormControl sx={{ m: 1, minWidth: 440 }}>
                <InputLabel htmlFor="component-outlined-CompanyName">Company Legal Name</InputLabel>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <OutlinedInput
                    id="component-outlined-CompanyName"
                    name="companyName"
                    value={paperworkData.companyName || ''}
                    onChange={handleChange}
                    label="Company Legal Name"
                    type="text"
                    sx={{ height: 56, flex: 1 }}
                    endAdornment={
                      <InputAdornment position="end">
                        <Tooltip title="Legal name of organization" placement="right">
                          <IconButton edge="end">
                            <InfoOutlined />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    }
                  />
                </Box>
              </FormControl>
              {/* Federal ID Number */}
              <FormControl sx={{ m: 1, minWidth: 440 }}>
                <InputLabel htmlFor="component-outlined-federalIDNumber">Federal ID Number</InputLabel>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <OutlinedInput
                    id="component-outlined-federalIDNumber"
                    name="federalIDNumber"
                    value={paperworkData.federalIDNumber || ''}
                    onChange={handleChange}
                    label="Federal ID Number"
                    type="number"
                    sx={{ height: 56, flex: 1 }}
                    endAdornment={
                      <InputAdornment position="end">
                        <Tooltip title="Organization's Federal ID Number, must consist of 9 digits." placement="right">
                          <IconButton edge="end">
                            <InfoOutlined />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    }
                  />
                </Box>
              </FormControl>

              <Divider sx={{ mt: 2, mb: 2 }} textAlign="left">
                Responsible for Compliance
              </Divider>

              {/* Name (Optional) */}
              <FormControl sx={{ m: 1, minWidth: 440 }}>
                <InputLabel htmlFor="component-outlined-ownerName">Name (Optional)</InputLabel>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <OutlinedInput
                    id="component-outlined-ownerName"
                    name="ownerName"
                    value={paperworkData.ownerName || ''}
                    onChange={handleChange}
                    label="Name (Optional)"
                    type="text"
                    sx={{ height: 56, flex: 1 }}
                  />
                </Box>
              </FormControl>

              {/* Email (Optional) */}
              <FormControl sx={{ m: 1, minWidth: 440 }}>
                <InputLabel htmlFor="component-outlined-ownerEmail">Email (Optional)</InputLabel>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <OutlinedInput
                    id="component-outlined-ownerEmail"
                    name="ownerEmail"
                    value={paperworkData.ownerEmail || ''}
                    onChange={handleChange}
                    label="Email (Optional)"
                    type="email"
                    sx={{ height: 56, flex: 1 }}
                  />
                </Box>
              </FormControl>

              {/* Phone (Optional) */}
              <FormControl sx={{ m: 1, minWidth: 440 }}>
                <InputLabel htmlFor="component-outlined-ownerPhone">Phone (Optional)</InputLabel>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <OutlinedInput
                    id="component-outlined-ownerPhone"
                    name="ownerPhone"
                    value={paperworkData.ownerPhone || ''}
                    onChange={handleChange}
                    label="Phone (Optional)"
                    type="tel"
                    sx={{ height: 56, flex: 1 }}
                  />
                </Box>
              </FormControl>

              <Divider sx={{ mt: 2, mb: 2 }} textAlign="left">
                Legal Address{' '}
              </Divider>

              {/* Address1 */}
              <FormControl sx={{ m: 1, minWidth: 440 }}>
                <InputLabel htmlFor="component-outlined-address1">Address 1</InputLabel>
                <OutlinedInput
                  id="component-outlined-address1"
                  name="address1"
                  value={paperworkData.address1 || ''}
                  onChange={handleChange}
                  label="Address 1"
                  type="text"
                  sx={{ mt: 1, height: 56, flex: 1 }}
                />
              </FormControl>
              {/* Address2 */}
              <FormControl sx={{ m: 1, minWidth: 440 }}>
                <InputLabel htmlFor="component-outlined-address2">Address 2</InputLabel>
                <OutlinedInput
                  id="component-outlined-address2"
                  name="address2"
                  value={paperworkData.address2 || ''}
                  onChange={handleChange}
                  label="Address 2"
                  type="text"
                  sx={{ mt: 1, height: 56, flex: 1 }}
                />
              </FormControl>
              {/* State */}
              <FormControl sx={{ m: 1, minWidth: 440 }}>
                <InputLabel id="demo-simple-select-label">State</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="state"
                  value={paperworkData.state || ''}
                  label="state"
                  onChange={handleChange}
                  sx={{ height: 56 }}
                >
                  {states.map((state) => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* City */}
              <FormControl sx={{ m: 1, minWidth: 440 }}>
                <InputLabel htmlFor="component-outlined-city">City</InputLabel>
                <OutlinedInput
                  id="component-outlined-city"
                  name="city"
                  value={paperworkData.city || ''}
                  onChange={handleChange}
                  label="City"
                  type="text"
                  sx={{ height: 56 }}
                />
              </FormControl>
              {/* Zipcode */}
              <FormControl sx={{ m: 1, minWidth: 440 }}>
                <InputLabel htmlFor="component-outlined-zipcode">Zipcode</InputLabel>
                <OutlinedInput
                  id="component-outlined-zipcode"
                  name="zipcode"
                  value={paperworkData.zipcode || ''}
                  onChange={handleChange}
                  label="Zipcode"
                  type="text"
                  sx={{ height: 56 }}
                />
              </FormControl>

              <Divider sx={{ mt: 2, mb: 2 }} textAlign="left">
                Documentation{' '}
              </Divider>
              {/* Business License or Important Documentation */}
              <FormControl sx={{ m: 1, width: 800 }}>
                <TextField
                  id="component-outlined-businessLicense"
                  label={paperworkDataFields['businessLicense']['userFriendlyName']}
                  name="businessLicense"
                  value={paperworkData.businessLicense?.filename || ''}
                  onChange={handleChange}
                  variant="outlined"
                  sx={{ width: '80%' }}
                  endAdornment={
                    <InputAdornment position="end">
                      <Tooltip title={paperworkDataFields['businessLicense']['tooltip']} placement="right">
                        <IconButton edge="end">
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  }
                  InputProps={{
                    readOnly: true,
                    endAdornment: paperworkData.businessLicense?.url ? (
                      <>
                        <IconButton
                          color="primary"
                          onClick={() => window.open(paperworkData.businessLicense?.url, '_blank')}
                          sx={{ m: 1 }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton color="error" onClick={() => handleFileDelete('businessLicense')} sx={{ m: 1 }}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    ) : (
                      <FileUpload
                        onSuccess={({ url, filename, docId }) =>
                          handleFileUpload('businessLicense', {
                            url,
                            filename,
                            docId,
                          })
                        }
                      />
                    ),
                  }}
                />
              </FormControl>

              {/* Save button */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <Button variant="contained" sx={{ m: 1, minWidth: 120, height: 56 }} onClick={handleSave}>
                  Save
                </Button>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                ml: 4,
              }}
            >
              {missingRequiredFields.length > 0 && (
                <Alert variant="outlined" severity="warning" sx={{ mb: 2, width: '100%' }}>
                  Missing required fields:{' '}
                  {missingRequiredFields.map(([field, config]) => config.userFriendlyName).join(', ')}
                </Alert>
              )}
              {missingDelayImpactFields.length > 0 && (
                <Alert variant="outlined" severity="info" sx={{ width: '100%' }}>
                  Missing fields which might cause process delays:{' '}
                  {missingDelayImpactFields.map(([field, config]) => config.userFriendlyName).join(', ')}
                </Alert>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

export default CompanyInformation
