import {fetchWithAuth} from "./utils";

export const submitComplianceFeedback = async (requestData) => {
  const url = process.env.REACT_APP_GCF_COMPLIANCE
  return fetchWithAuth(url, {
    method: 'POST',
    body: JSON.stringify(requestData),
  })
}

export const startAvaPdfRun = async (requestData) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/tools/avapdf`
  return fetchWithAuth(url, {
    method: 'POST',
    body: JSON.stringify(requestData),
  })
}

export const startAvaSmsRun = async (requestData) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/tools/avasms`
  return fetchWithAuth(url, {
    method: 'POST',
    body: JSON.stringify(requestData),
  })
}

export const startAvaWebRun = async (requestData) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/tools/avaweb`
  return fetchWithAuth(url, {
    method: 'POST',
    body: JSON.stringify(requestData),
  })
}

export const startRegistrationRenewal = async (requestData) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/tasks/start-registration-renewal`
  return fetchWithAuth(url, {
    method: 'POST',
    body: JSON.stringify(requestData),
  })
}

export const scheduleInspection = async (requestData) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/tasks/schedule-inspection`
  return fetchWithAuth(url, {
    method: 'POST',
    body: JSON.stringify(requestData),
  })
}

export const startAvaAuditorRun = async (requestData) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/tools/avaauditor`
  return fetchWithAuth(url, {
    method: 'POST',
    body: JSON.stringify(requestData),
  })
}

export const retryProcess = async (requestData) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/retry`
  return fetchWithAuth(url, {
    method: 'POST',
    body: JSON.stringify(requestData),
  })
}

export const submitFeedback = async (requestData) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/feedback`
  return fetchWithAuth(url, {
    method: 'POST',
    body: JSON.stringify(requestData),
  })
}
