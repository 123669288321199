import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import { useUser } from '../contexts/UserContext'

const ConfirmationModal = ({
  open,
  onClose,
  handleConfirm,
  selectedRun,
  confirmationMessage,
}) => {
  const confirmFunction = async () => {
    handleConfirm.then(async (result) => await result(selectedRun))
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Confirm Action</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {confirmationMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={confirmFunction} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationModal
