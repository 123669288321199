import { collection, getDocs, setDoc, deleteDoc, doc } from 'firebase/firestore'
import { db } from '../../firebaseConfig'

export const fetchUsersCompanyInvites = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'fairway', 'onboarding', 'newCompany'))
    return querySnapshot.docs.map((doc) => ({
      email: doc.id,
      ...doc.data(),
    }))
  } catch (error) {
    console.error('Error fetching companies: ', error)
    throw error
  }
}

export const saveNewCompanyInvite = async (newCompanyInviteData) => {
  try {
    const docRef = doc(db, 'fairway', 'onboarding', 'newCompany', newCompanyInviteData.email.toLowerCase())
    await setDoc(docRef, newCompanyInviteData)
  } catch (error) {
    console.error('Error saving new company: ', error)
    throw error
  }
}

export const deleteCompanyInvite = async (email) => {
  try {
    await deleteDoc(doc(db, 'fairway', 'onboarding', 'newCompany', email))
  } catch (error) {
    console.error('Error deleting company: ', error)
    throw error
  }
}
