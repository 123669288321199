import { db } from '../../firebaseConfig'
import { doc, getDoc } from 'firebase/firestore'
import { submitComplianceFeedback } from '../../api/fetchFairway'

export const fetchComplianceData = async () => {
  try {
    const docRef = doc(db, 'fairway', 'vehicleComplianceKnowledgeBase')
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      return docSnap.data()
    } else {
      throw new Error('No such document!')
    }
  } catch (error) {
    console.error('Error fetching document:', error)
    throw new Error('Error fetching data from Firestore.')
  }
}

export const submitFeedback = async (requestData) => {
  try {
    const response = await submitComplianceFeedback(requestData)

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    return await response.json()
  } catch (error) {
    console.error('Error:', error)
    throw new Error(error.message)
  }
}
