import { v4 as uuidv4 } from 'uuid'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { collection, addDoc, serverTimestamp } from 'firebase/firestore'
import { storage, db } from '../../firebaseConfig'

// Function to upload file to Firebase Storage
export const uploadFile = async (file, user) => {
  const uniqueId = uuidv4()
  const fileName = `${uniqueId}-${file.name}`
  const storagePath = `uploads/${user.companyId}/${user.uid}/${fileName}`
  const storageRef = ref(storage, storagePath)

  // Upload file to Firebase Storage
  const snapshot = await uploadBytes(storageRef, file)
  const url = await getDownloadURL(snapshot.ref)
  const gsUrl = `gs://${storageRef.bucket}/${storageRef.fullPath}`

  return { url, gsUrl, fileName }
}

// Function to add file metadata to Firestore
export const addFileMetadata = async (user, fileData) => {
  const collectionRef = collection(db, `customers/${user.companyId}/users/${user.uid}/files`)
  const docRef = await addDoc(collectionRef, {
    ...fileData,
    uploadedAt: serverTimestamp(),
  })

  return { id: docRef.id }
}
