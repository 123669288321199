import { createDocument, deleteDocument, setCollection, setDocument, subscribeToCollection } from '../databaseUtils'
import { orderBy, query } from 'firebase/firestore'

// Function to create a new file

export const addPdfRun = async ({
  customer_id,
  vehicle_id,
  additional_instructions,
  status,
  run_datetime,
  user_name,
  user_id,
  target_pdf,
  completed_pdf,
}) => {
  const newRunFile = {
    customer_id,
    vehicle_id,
    additional_instructions,
    status,
    run_datetime,
    user_name,
    user_id,
    target_pdf,
    completed_pdf,
  }

  const collectionPath = ['customers', customer_id, 'tools', 'AvaPDF', 'runs']

  const result = await createDocument(collectionPath, newRunFile)
  return result ? { id: result.id } : null
}
//deletePdfRun
export const deletePdfRun = async (companyId, fileId) => {
  await deleteDocument(['customers', companyId, 'tools', 'AvaPDF', 'files', fileId])
}

export const setPdfRunStatus = async (companyId, runId, status) => {
  const collectionPath = ['customers', companyId, 'tools', 'PDF', 'runs']
  const data = {
    status: status,
    result: '',
  }
  const options = { merge: true }

  return await setDocument(collectionPath, runId, data, options)
}

const queryRuns = (companyId) =>
  query(setCollection(['customers', companyId, 'tools', 'AvaPDF', 'runs']), orderBy('run_datetime', 'desc'))

export const subscribeToPdfRuns = (companyId, onUpdate, onError) =>
  subscribeToCollection(queryRuns(companyId), onUpdate, onError)
