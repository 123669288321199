import { Typography, Box } from '@mui/material'
import { useColorMode } from '../theme'

const Header = ({ title, subtitle }) => {
  const { theme } = useColorMode()
  return (
    <Box mb="30px">
      <Typography variant="h4" color={theme.palette.text.primary} fontWeight="bold" sx={{ m: '0 0 5px 0' }}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="h7" color={theme.palette.success.main}>
          {subtitle}
        </Typography>
      )}
    </Box>
  )
}

export default Header
