import React, { useState } from 'react'
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { updateFeedback } from '../database/services/feedback'
import { submitFeedback } from '../api/fetchFairway'
import { useUser } from '../contexts/UserContext'

const FeedbackModal = ({
  open,
  onClose,
  tool,
  selectedRun,
  showCorrectAnswerDropdown = true,
  correctAnswerOptions = [],
  showRejectionReasons = true,
  rejectionReasonOptions = [],
  showAdditionalFeedback = true,
  shouldMakeFetch = true,
}) => {
  const { company } = useUser()
  const [wasCorrect, setWasCorrect] = useState('')
  const [correctAnswer, setCorrectAnswer] = useState('')
  const [rejectionReason, setRejectionReason] = useState([])
  const [additionalFeedback, setAdditionalFeedback] = useState('')

  const onCloseWrapper = () => {
    onClose()
    setWasCorrect('')
    setCorrectAnswer('')
    setRejectionReason([])
    setAdditionalFeedback('')
  }

  const handleFeedbackSubmit = async () => {
    try {
      if (shouldMakeFetch) {
        const feedbackData = {
          customer: company.id,
          tool,
          tool_run: selectedRun.id,
          trace_run_id: selectedRun.trace_run_id,
          key: 'user_feedback',
          score: wasCorrect === 'no' ? 0.0 : 1.0,
          correction:
            wasCorrect === 'yes'
              ? null
              : {
                  correct_answer: showCorrectAnswerDropdown ? correctAnswer : null,
                  rejection_reason: showRejectionReasons && rejectionReason.length > 0 ? rejectionReason : null,
                },
          comment: additionalFeedback || null,
        }

        const response = await submitFeedback(feedbackData)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
      }

      await updateFeedback(company.id, tool, selectedRun.id, {
        wasCorrect,
        correctAnswer: showCorrectAnswerDropdown && wasCorrect === 'no' ? correctAnswer : null,
        rejectionReason:
          showRejectionReasons && wasCorrect === 'no' && correctAnswer === 'rejected' ? rejectionReason : null,
        additionalFeedback,
      })

      alert('Feedback submitted successfully!')
    } catch (error) {
      console.error(`Error submitting feedback: ${error}`)
    } finally {
      onCloseWrapper()
    }
  }

  const isSubmitDisabled = () => {
    if (wasCorrect === '') return true
    if (wasCorrect === 'no') {
      // Only check for correctAnswer if the dropdown is shown
      if (showCorrectAnswerDropdown && correctAnswer === '') return true
      // Only check for rejectionReason if the rejection reasons are shown
      if (showRejectionReasons && correctAnswer === 'rejected' && rejectionReason.length === 0) return true
    }
    return false
  }

  return (
    <Modal open={open} onClose={onCloseWrapper}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          maxHeight: '90vh',
          overflowY: 'auto',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography variant="h6" id="modal-modal-title">
            Leave Feedback
          </Typography>
          <IconButton onClick={onCloseWrapper}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ flexGrow: 1, overflowY: 'auto', mb: 2 }}>
          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <FormLabel component="legend">Was the answer correct?</FormLabel>
            <RadioGroup row value={wasCorrect} onChange={(e) => setWasCorrect(e.target.value)}>
              <FormControlLabel value="yes" style={{ marginLeft: 0 }} control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
          {wasCorrect === 'yes' && showAdditionalFeedback && (
            <TextField
              fullWidth
              multiline
              rows={2}
              value={additionalFeedback}
              onChange={(e) => setAdditionalFeedback(e.target.value)}
              placeholder="Anything else to add?"
              sx={{ mb: 2 }}
            />
          )}
          {wasCorrect === 'no' && (
            <>
              {showCorrectAnswerDropdown && (
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <FormLabel>What would be the correct answer?</FormLabel>
                  <Select value={correctAnswer} onChange={(e) => setCorrectAnswer(e.target.value)}>
                    {correctAnswerOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {correctAnswer === 'rejected' && showRejectionReasons && (
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <FormLabel>Please select all the rejection reasons:</FormLabel>
                  <Select
                    multiple
                    value={rejectionReason}
                    onChange={(e) => setRejectionReason(e.target.value)}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {rejectionReasonOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {showAdditionalFeedback && (
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  value={additionalFeedback}
                  onChange={(e) => setAdditionalFeedback(e.target.value)}
                  placeholder="Anything else to add?"
                  sx={{ mb: 2 }}
                />
              )}
            </>
          )}
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFeedbackSubmit}
          disabled={isSubmitDisabled()}
          sx={{ mb: 2 }}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  )
}

export default FeedbackModal
