import { db } from '../../firebaseConfig'
import { doc, getDoc, deleteDoc, setDoc, collection, getDocs, updateDoc, query, where } from 'firebase/firestore'

export const fetchDriver = async (companyId, driverId) => {
  try {
    const driverDoc = await getDoc(doc(db, 'customers', companyId, 'drivers', driverId))
    if (driverDoc.exists()) {
      return driverDoc.data()
    } else {
      console.error('No such driver document!')
      return null
    }
  } catch (error) {
    console.error('Error fetching driver: ', error)
    return null
  }
}

export const createDriver = async (companyId, driver) => {
  try {
    const newDocRef = doc(collection(db, 'customers', companyId, 'drivers'))
    await setDoc(newDocRef, driver)
    return newDocRef.id
  } catch (error) {
    console.error('Error creating new driver document: ', error)
    return null
  }
}

export const updateDriver = async (companyId, driverId, driver) => {
  try {
    await setDoc(doc(db, 'customers', companyId, 'drivers', driverId), driver)
    return true
  } catch (error) {
    console.error('Error saving driver information: ', error)
    return false
  }
}

export const updateVehiclesWithDriver = async (companyId, driverId, driverName, selectedVehicles) => {
  try {
    await Promise.all(
      selectedVehicles.map((vehicleId) =>
        updateDoc(doc(db, 'customers', companyId, 'vehicles', vehicleId), {
          driverID: driverId,
          driverName: driverName,
        })
      )
    )
    return true
  } catch (error) {
    console.error('Error updating vehicles: ', error)
    return false
  }
}

export const fetchDrivers = async (companyId) => {
  try {
    const driversSnapshot = await getDocs(query(collection(db, 'customers', companyId, 'drivers')))
    const vehiclesSnapshot = await getDocs(query(collection(db, 'customers', companyId, 'vehicles')))
    const vehiclesData = vehiclesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))

    return driversSnapshot.docs.map((doc) => {
      const driverData = { id: doc.id, ...doc.data() }
      const driverVehicles = vehiclesData.filter((vehicle) => vehicle.driverID === driverData.id)
      driverData.vehicles = driverVehicles.map((vehicle) => ({
        name: vehicle.carName,
        make: vehicle.specification?.make,
        model: vehicle.specification?.model,
        year: vehicle.specification?.year,
      }))
      return driverData
    })
  } catch (error) {
    console.error('Error fetching drivers: ', error)
    throw error
  }
}

export const deleteDriver = async (companyId, driverId) => {
  try {
    await deleteDoc(doc(db, 'customers', companyId, 'drivers', driverId))
  } catch (error) {
    console.error('Error deleting driver: ', error)
    throw error
  }
}
