import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import CustomDataGrid from '../../components/CustomDataGrid'
import { useColorMode } from '../../theme'
import { useUser } from '../../contexts/UserContext'
import AvaSMSRunModal from './avaSMSRunModal'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ContactTable from './contactTable'
import ReviewsOutlinedIcon from '@mui/icons-material/ReviewsOutlined'
import { subscribeToSMSRuns, addAvaSmsRun, updateAvaSmsRun } from '../../database/services/avaSms'
import { subscribeToVehicles } from '../../database/services/vehicles'
import FeedbackModal from '../../components/FeedbackModal'
import { startAvaSmsRun } from '../../api/fetchFairway'

const AvaSms = () => {
  const { theme } = useColorMode()
  const { user, company, backendUrl } = useUser()
  const [avaSmsRuns, setAvaSmsRuns] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedRun, setSelectedRun] = useState(null)
  const [open, setOpen] = useState(false)
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false)
  const [formOpen, setFormOpen] = useState(false)
  const [formData, setFormData] = useState({
    max_iterations: 10,
    vehicle_id: '',
    context: '',
    goal: '',
    output_format: '',
    contacts_of_interest: [{ name: '', phone_number: '', role: '' }],
  })
  const [vehicles, setVehicles] = useState([]) // State to store vehicles
  const [isSubmitting, setIsSubmitting] = useState(false)

  const formatDate = (date) => {
    const pad = (num) => num.toString().padStart(2, '0')

    const year = date.getFullYear()
    const month = pad(date.getMonth() + 1) // Months are zero-based, so add 1
    const day = pad(date.getDate())
    const hours = pad(date.getHours())
    const minutes = pad(date.getMinutes())

    return `${year}-${month}-${day} ${hours}:${minutes}`
  }

  const getStatusColor = (status) => {
    switch (status) {
      case 'Done':
        return '#00FF00'
      case 'Failed':
        return '#FF0000'
      case 'Error':
        return '#FF0000'
      case 'In Progress':
      case 'Created':
        return '#FFFF00'
      default:
        return '#000000'
    }
  }

  const actionIcons = {
    leaveFeedback: { tooltip: 'Leave Feedback', icon: <ReviewsOutlinedIcon /> },
  }

  // Add a last column "# Iterations"
  // It should be the length of .iteration / .max_iterations
  const avaSmsRunsColumns = [
    {
      field: 'view',
      headerName: 'View',
      width: 100,
      renderCell: (params) => (
        <IconButton onClick={() => handleView(params.row)}>
          <VisibilityIcon />
        </IconButton>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 130,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" mt="15px">
          <Box
            sx={{
              width: 10,
              height: 10,
              bgcolor: getStatusColor(params.value),
              borderRadius: '50%',
              display: 'inline-block',
              marginRight: 1,
            }}
          />
          <Typography variant="body2">{params.value}</Typography>
        </Box>
      ),
    },
    { field: 'initial_datetime', headerName: 'Run Time', width: 150 },
    {
      field: 'user_id',
      headerName: 'User Name',
      width: 150,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" mt="15px">
          <Typography variant="body2">{params.row.user_name ?? params.row.user_name}</Typography>
        </Box>
      ),
    },
    {
      field: 'vehicle_id',
      headerName: 'Vehicle VIN',
      width: 170,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" mt="15px">
          <Typography variant="body2">{params.row.input_data?.vehicle_id}</Typography>
        </Box>
      ),
    },
    {
      field: 'goal',
      headerName: 'Goal',
      width: 400,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" mt="15px">
          <Typography variant="body2">{params.row.input_data?.objective?.goal}</Typography>
        </Box>
      ),
    },
    {
      field: 'result',
      headerName: 'Result',
      width: 300,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" mt="15px">
          <Typography variant="body2">
            {(() => {
              // Check if the result is null or undefined
              if (params.row.result === null || params.row.result === undefined) {
                return ''
              }
              // If the result is an object, check if it contains an error
              if (typeof params.row.result === 'object') {
                return params.row.result.error
                  ? params.row.result.error
                  : params.row.result.additional_information
                    ? params.row.result.additional_information
                    : ''
              }
              // If the result is a string, display it directly
              return params.row.result
            })()}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'iterations',
      headerName: '# Iterations',
      width: 150,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" mt="15px">
          <Typography variant="body2">
            {params.row.iteration
              ? `${params.row.iteration.length}/${params.row.max_iterations}`
              : `0/${params.row.max_iterations}`}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'moreActions',
      headerName: 'More Actions',
      width: 180,
      renderCell: (params) => (
        <Box display="flex">
          {Object.keys(actionIcons).map((action) => (
            <Tooltip key={action} title={actionIcons[action].tooltip}>
              <IconButton onClick={() => handleActionView(action, params.row)}>{actionIcons[action].icon}</IconButton>
            </Tooltip>
          ))}
        </Box>
      ),
    },
  ]

  useEffect(() => {
    if (!company) return

    // Subscribe to AvaSMS runs data
    const unsubscribeRuns = subscribeToSMSRuns(
      company.id,
      (runsList) => {
        setAvaSmsRuns(runsList)
        setLoading(false)
      },
      (error) => {
        console.error('Error fetching runs data:', error)
        setLoading(false)
      }
    )
    // Subscribe to vehicles data
    const unsubscribeVehicles = subscribeToVehicles(
      company.id,
      (vehiclesList) => {
        setVehicles(vehiclesList)
      },
      (error) => {
        console.error('Error fetching vehicles: ', error)
      }
    )

    return () => {
      if (unsubscribeRuns) unsubscribeRuns()
      if (unsubscribeVehicles) unsubscribeVehicles()
    }
  }, [company])

  const handleActionView = (action, row) => {
    switch (action) {
      case 'leaveFeedback':
        setSelectedRun(row)
        setFeedbackModalOpen(true)
        break

      default:
        console.error(`Action not implemented: ${action}`)
        alert('Action not implemented')
    }
  }

  const handleFeedbackCloseModal = () => {
    setFeedbackModalOpen(false)
    setSelectedRun(null)
  }

  const handleView = (run) => {
    setSelectedRun(run)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedRun(null)
  }

  const handleFormClose = () => {
    setFormOpen(false)
    setFormData({
      max_iterations: 10,
      vehicle_id: '',
      context: '',
      goal: '',
      output_format: '',
      contacts_of_interest: [{ name: '', phone_number: '', role: '' }],
    })
  }

  const handleAddAvaSmsRun = async () => {
    setIsSubmitting(true)

    if (formData.context === '') {
      formData.context =
        'Help driver to schedule an inspection in order to complete a registration renewal. The appointment must be scheduled in the next 7 days.'
    }
    if (formData.goal === '') {
      formData.goal = 'Schedule an inspection appointment for the driver'
    }
    if (formData.output_format === '') {
      formData.output_format =
        'Dictionary with location_name, location_adress, appointment_date with format YYYY-MM-DD hh:mm, additional_information (string)'
    }
    const runData = {
      status: 'Created',
      objective: {
        context: formData.context,
        goal: formData.goal,
        output_format: formData.output_format,
      },
      contacts_of_interest: formData.contacts_of_interest,
      max_iterations: formData.max_iterations,
      vehicle_id: formData.vehicle_id,
      initial_datetime: formatDate(new Date()),
      user_name: user.name,
      user_id: user.uid,
      result: null,
    }
    const runDocRef = await addAvaSmsRun(company.id, runData)
    try {
      const requestData = {
        objective: {
          context: formData.context,
          goal: formData.goal,
          output_format: formData.output_format,
        },
        max_iterations: formData.max_iterations,
        contacts_of_interest: formData.contacts_of_interest,
        vehicle_id: formData.vehicle_id,
        customer_id: company.id,
        additional_information: {},
        user_id: user.uid,
        tool_run_id: runDocRef.id,
        id: `${formData.vehicle_id}-${Math.floor(10000 + Math.random() * 90000)}`,
      }

      const response = await startAvaSmsRun(requestData)
      const data = await response.json()

      if (response.ok) {
        console.log(data)
        alert(`Success: ${data.message}`)
      } else {
        throw new Error(data.message || 'Failed to start AvaSMS run')
      }
    } catch (error) {
      console.error('Error processing request: ', error)
      await updateAvaSmsRun(company.id, runDocRef.id, {
        status: 'Failed',
        result: error.message || 'Unknown error',
      })
    } finally {
      setFormData({
        max_iterations: 10,
        vehicle_id: '',
        context: '',
        goal: '',
        output_format: '',
        contacts_of_interest: [{ name: '', phone_number: '', role: '' }],
      })
      setIsSubmitting(false)
      setFormOpen(false)
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: name === 'max_iterations' ? Number(value) : value,
    }))
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography variant="h6">Runs</Typography>
            <Button variant="contained" startIcon={<AddIcon />} onClick={() => setFormOpen(true)}>
              Add
            </Button>
          </Box>
          <Box
            sx={{
              minHeight: 400,
              width: '100%',
              overflowX: 'auto',
              overflowY: 'auto',
              paddingBottom: '0px',
              '&::-webkit-scrollbar': {
                width: '12px',
                height: '12px',
              },
              '&::-webkit-scrollbar-track': {
                background: theme.palette.background.secondary,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.background.primary,
                borderRadius: '10px',
                border: `3px solid ${theme.palette.background.secondary}`,
              },
              '& *': {
                scrollbarWidth: 'thin',
                scrollbarColor: `${theme.palette.background.primary} ${theme.palette.background.secondary}`,
              },
            }}
          >
            <CustomDataGrid
              rows={avaSmsRuns}
              columns={avaSmsRunsColumns}
              pageSize={20}
              loading={loading}
              disableRowSelectionOnClick={true}
              autoHeight
            />
          </Box>
        </Grid>
      </Grid>
      <AvaSMSRunModal open={open} handleClose={handleClose} selectedRun={selectedRun} />

      <Modal open={formOpen} onClose={handleFormClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxHeight: '90%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            overflowY: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography variant="h6">Add Ava SMS Run</Typography>
            <IconButton onClick={handleFormClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            component="form"
            onSubmit={(e) => {
              e.preventDefault()
              handleAddAvaSmsRun()
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Box sx={{ bgcolor: 'background.paper', p: 2, borderRadius: 1 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Objective
                  </Typography>
                  <TextField
                    label="Context"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Help driver to schedule an inspection in order to complete a registration renewal. The appointment must be scheduled in the next 7 days."
                    name="context"
                    value={formData.context}
                    onChange={handleInputChange}
                    fullWidth
                    multiline
                    rows={3}
                    margin="normal"
                  />
                  <TextField
                    label="Goal"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Schedule an inspection appointment for the driver"
                    name="goal"
                    value={formData.goal}
                    onChange={handleInputChange}
                    fullWidth
                    multiline
                    rows={2}
                    margin="normal"
                  />
                  <TextField
                    label="Output format"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Dictionary with location_name, location_adress, appointment_date with format YYYY-MM-DD hh:mm, additional_information (string)"
                    name="output_format"
                    value={formData.output_format}
                    onChange={handleInputChange}
                    fullWidth
                    multiline
                    rows={3}
                    margin="normal"
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ bgcolor: 'background.paper', p: 2, borderRadius: 1 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Settings
                  </Typography>
                  <FormControl fullWidth variant="outlined" sx={{ mt: 2, mb: 1 }}>
                    <InputLabel>Vehicle VIN</InputLabel>
                    <Select
                      value={formData.vehicle_id}
                      onChange={handleInputChange}
                      label="Vehicle VIN"
                      name="vehicle_id"
                    >
                      {vehicles.map((vehicle) => (
                        <MenuItem key={vehicle.vin} value={vehicle.vin}>
                          {vehicle.vin}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Max Iterations"
                    name="max_iterations"
                    value={formData.max_iterations}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    type="number"
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <ContactTable formData={formData} setFormData={setFormData} />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Button type="submit" variant="contained" sx={{ mt: 2 }} disabled={isSubmitting}>
                    Add Run
                  </Button>
                  {isSubmitting && <CircularProgress size={24} sx={{ ml: 2 }} />}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>

      <FeedbackModal
        open={feedbackModalOpen}
        onClose={handleFeedbackCloseModal}
        tool="AvaSMS"
        selectedRun={selectedRun}
        showCorrectAnswerDropdown={false}
        showRejectionReasons={false}
        showAdditionalFeedback={true}
        shouldMakeFetch={false} // New prop set to false
      />
    </Box>
  )
}

export default AvaSms
