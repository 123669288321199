import { collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore'
import { db } from '../../firebaseConfig'

export const getAgentsInformation = async () => {
  const statesSnapshot = await getDocs(collection(db, 'agentInformation'))
  return statesSnapshot.docs.map((doc) => doc.id)
}

export const getPaperworkData = async (companyId) => {
  const docRef = doc(db, 'customers', companyId, 'settings', 'paperworkData')
  const docSnap = await getDoc(docRef)
  return docSnap.exists() ? docSnap.data() : null
}

export const addPaperworkData = async (company, data) => {
  const docRef = doc(db, 'customers', company.id, 'settings', 'paperworkData')
  await setDoc(docRef, data)
}

// Function to get all settings documents
export const getAllSettings = async (companyId) => {
  const collectionRef = collection(db, 'customers', companyId, 'settings')
  const snapshot = await getDocs(collectionRef)
  return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
}

export const addCustomer = async (company, data) => {
  const docRef = doc(db, 'customers', company.id)
  await setDoc(docRef, data)
}
