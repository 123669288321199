import { useTheme } from '@mui/material'
import { ResponsiveBar } from '@nivo/bar'
import { useColorMode } from '../theme'
import { mockHorizontalBarChartData as data } from '../data/mockData'

const HorizontalBarChart = ({ isDashboard = false }) => {
  const { theme } = useColorMode()
  return (
    <ResponsiveBar
      data={data}
      theme={{
        // added
        axis: {
          domain: {
            line: {
              stroke: theme.palette.text.secondary,
            },
          },
          legend: {
            text: {
              fill: theme.palette.text.secondary,
            },
          },
          ticks: {
            line: {
              stroke: theme.palette.text.secondary,
              strokeWidth: 1,
            },
            text: {
              fill: theme.palette.text.secondary,
            },
          },
        },
        legends: {
          text: {
            fill: theme.palette.text.secondary,
          },
        },
        tooltip: {
          container: {
            color: theme.palette.primary[500],
          },
        },
      }}
      keys={['opportunities']}
      indexBy="supplier"
      margin={{ top: 50, right: 40, bottom: 50, left: 160 }}
      padding={0.3}
      groupMode="grouped"
      layout="horizontal"
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      // colors={{ scheme: 'nivo' }}
      colors={['#6870fa']}
      // colorBy="indexValue"
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: '#38bcb2',
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: '#eed312',
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: 'fries',
          },
          id: 'dots',
        },
        {
          match: {
            id: 'sandwich',
          },
          id: 'lines',
        },
      ]}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'opportunities',
        legendPosition: 'middle',
        legendOffset: 32,
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'supplier',
        legendPosition: 'middle',
        legendOffset: -130,
        truncateTickAt: 0,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      legends={[]}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={(e) => e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue}
    />
  )
}

export default HorizontalBarChart
