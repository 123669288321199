import { readDocument } from '../databaseUtils'
import { collection, getDocs, addDoc, setDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore'
import { db } from '../../firebaseConfig'
import { getAuth } from 'firebase/auth'

const auth = getAuth()
export const getAuthInstance = () => auth

// Function to fetch user data
export const fetchUserData = async (userId) => {
  const docPath = ['users', userId]
  return await readDocument(docPath)
}

// Function to fetch company data
export const fetchCompanyData = async (companyId) => {
  const docPath = ['customers', companyId]
  return await readDocument(docPath)
}

export const fetchUsers = async (companyId) => {
  const usersCollection = collection(db, 'customers', companyId, 'users')
  const usersSnapshot = await getDocs(usersCollection)
  return usersSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }))
}

export const fetchInvitedUsers = async (companyId) => {
  const invitedUsersRef = collection(db, 'customers', companyId, 'settings', 'permissions', 'invitedUsers')
  const invitedUsersSnapshot = await getDocs(invitedUsersRef)
  return invitedUsersSnapshot.docs.map((doc) => ({
    inviteEmail: doc.id,
    inviteId: doc.data().inviteId,
  }))
}

export const inviteUser = async (companyId, companyName, userEmail, invitedBy) => {
  const inviteRef = await addDoc(collection(db, 'fairway', 'onboarding', 'companyInvitation'), {
    companyId,
    companyName,
    userEmail,
    invitedBy,
  })
  const inviteId = inviteRef.id
  await setDoc(doc(db, 'customers', companyId, 'settings', 'permissions', 'invitedUsers', userEmail), {
    inviteId,
  })
  return inviteId
}

export const deleteInvitedUser = async (companyId, inviteEmail, inviteId) => {
  await deleteDoc(doc(db, 'customers', companyId, 'settings', 'permissions', 'invitedUsers', inviteEmail))
  await deleteDoc(doc(db, 'fairway', 'onboarding', 'companyInvitation', inviteId))
}

export const updateUserProfile = async (userId, data) => {
  const docRef = doc(db, 'users', userId)
  await updateDoc(docRef, data)
}

export const fetchAdminUsers = async () => {
  try {
    const usersCollection = collection(db, 'fairway', 'permissions', 'superadmins')
    const usersSnapshot = await getDocs(usersCollection)
    return usersSnapshot.docs.map((doc) => ({
      id: doc.id,
      email: doc.id,
    }))
  } catch (error) {
    console.error('Error fetching data: ', error)
    throw new Error('Error fetching data from Firestore.')
  }
}

export const inviteAdminUser = async (email) => {
  try {
    const lowercasedEmail = email.toLowerCase()
    await setDoc(doc(db, 'fairway', 'permissions', 'superadmins', lowercasedEmail), {})
    return {
      id: lowercasedEmail,
      email: lowercasedEmail,
    }
  } catch (error) {
    console.error('Error inviting user: ', error)
    throw new Error('Error inviting user to Firestore.')
  }
}
