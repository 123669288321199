import { subscribeToCollection, setCollection, createDocument, setDocument, deleteDocument } from '../databaseUtils'
import { query } from 'firebase/firestore'

// Function to fetch AvaSMS runs with a subscription
export const subscribeToSMSRuns = (companyId, onUpdate, onError) => {
  try {
    const collectionPath = ['customers', companyId, 'tools', 'AvaSMS', 'runs']
    const runsCollection = setCollection(collectionPath)

    const queryConstraints = [{ type: 'orderBy', field: 'initial_datetime', direction: 'desc' }]
    const q = query(runsCollection, ...queryConstraints)

    return subscribeToCollection(q, onUpdate, onError)
  } catch (error) {
    console.error('Error subscribing to runs data:', error)
    onError(error)
    return null
  }
}

// Function to add a new AvaSMS run
export const addAvaSmsRun = async (companyId, runData) => {
  const collectionPath = ['customers', companyId, 'tools', 'AvaSMS', 'runs']
  return await createDocument(collectionPath, runData)
}

// Function to update an AvaSMS run
export const updateAvaSmsRun = async (companyId, runId, data) => {
  const collectionPath = ['customers', companyId, 'tools', 'AvaSMS', 'runs']
  return await setDocument(collectionPath, runId, data, { merge: true })
}

// Function to delete an AvaSMS run
export const deleteAvaSmsRun = async (companyId, runId) => {
  const docPath = ['customers', companyId, 'tools', 'AvaSMS', 'runs', runId]
  return await deleteDocument(docPath)
}
