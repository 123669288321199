import React, { useState } from 'react'
import { Box, IconButton, CircularProgress, Tooltip } from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import { uploadFile, addFileMetadata } from '../../database/services/files'

const FileUpload = ({ user, onSuccess, multiple }) => {
  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)

  const handleFileChange = async (event) => {
    const files = event.target.files
    if (!files.length) {
      setError('No file selected')
      return
    }

    setUploading(true)
    setError('') // Clear any existing errors

    for (const file of files) {
      try {
        const { url, gsUrl, fileName } = await uploadFile(file, user)
        const fileData = {
          name: file.name,
          size: file.size,
          type: file.type,
          downloadURL: url,
          gsURL: gsUrl,
          uploadedAt: new Date(),
        }
        const { id: docId } = await addFileMetadata(user, fileData)

        onSuccess({ url, gsUrl, filename: file.name, docId, size: file.size, type: file.type })
      } catch (uploadError) {
        setUploading(false)
        console.error('Error uploading file: ', uploadError)
        setError('Error uploading file. Please try again.')
      }
    }

    setUploading(false)
    setSuccess(true)
    setTimeout(() => setSuccess(false), 2000) // Hide success icon after 2 seconds
  }

  return (
    <Box display="flex" alignItems="center" ml={1}>
      <input
        type="file"
        onChange={handleFileChange}
        disabled={uploading}
        style={{ display: 'none', mr: 1 }}
        id="file-upload"
        multiple={multiple}
      />
      <label htmlFor="file-upload" sx={{ mr: 1 }}>
        <IconButton color="primary" component="span" disabled={uploading}>
          {uploading ? <CircularProgress size={24} /> : <UploadFileIcon />}
        </IconButton>
      </label>
      {success && (
        <Tooltip title="File uploaded successfully" sx={{ mr: 1 }}>
          <CheckCircleIcon color="success" />
        </Tooltip>
      )}
      {error && (
        <Tooltip title={error} sx={{ mr: 1 }}>
          <ErrorIcon color="error" />
        </Tooltip>
      )}
    </Box>
  )
}

export default FileUpload
