import { collection, addDoc, onSnapshot, setDoc, doc, query, orderBy } from 'firebase/firestore'
import { db } from '../firebaseConfig'

export const getAvaWebRuns = (companyId, callback, errorCallback) => {
  const runsCollection = collection(db, 'customers', companyId, 'tools', 'AvaWeb', 'runs')
  const q = query(runsCollection, orderBy('run_datetime', 'desc'))
  return onSnapshot(q, callback, errorCallback)
}

export const addAvaWebRun = async (companyId, runData) => {
  return await addDoc(collection(db, 'customers', companyId, 'tools', 'AvaWeb', 'runs'), runData)
}

export const updateAvaWebRun = async (companyId, runId, updateData) => {
  return await setDoc(doc(db, 'customers', companyId, 'tools', 'AvaWeb', 'runs', runId), updateData, { merge: true })
}
