import { useState, useEffect } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Header from '../../components/Header'
import { useColorMode } from '../../theme'
import {
  Box,
  IconButton,
  FormControl,
  InputLabel,
  Typography,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useUser } from '../../contexts/UserContext'
import { useNavigate, useLocation } from 'react-router-dom'
import { LanguagesPreferences } from './languagesPreferences'
import { fetchDriver, createDriver, updateDriver, updateVehiclesWithDriver } from '../../database/services/drivers'
import { fetchVehiclesByDriver, fetchVehicles } from '../../database/services/vehicles'

const DriverEdit = () => {
  const { theme } = useColorMode()
  const { user } = useUser()
  const [value, setValue] = useState(0)
  const location = useLocation()
  const navigate = useNavigate()
  const [driverId, setDriverId] = useState(location.pathname.split('/')[2] || null)
  const [driver, setDriver] = useState(null)
  const [loadingDriver, setLoadingDriver] = useState(true)
  const [isEditing, setIsEditing] = useState(location.pathname.split('/')[2] === 'new')
  const [vehicles, setVehicles] = useState([])
  const [selectedVehicles, setSelectedVehicles] = useState([])
  const [showUnassignedOnly, setShowUnassignedOnly] = useState(true)
  const [groupFilter, setGroupFilter] = useState('')

  const title = driverId === 'new' ? 'Create New Driver' : 'Edit Driver'
  const subtitle =
    driverId === 'new'
      ? 'Create a new driver for each operational center.'
      : `Edit the details for the ${driver?.name || 'selected'} driver.`

  useEffect(() => {
    const fetchDriverData = async (driverId) => {
      const driverData = await fetchDriver(user.companyId, driverId)
      if (driverData) {
        setDriver(driverData)
        const selectedVehicleIds = await fetchVehiclesByDriver(user.companyId, driverId)
        setSelectedVehicles(selectedVehicleIds)
      }
      setLoadingDriver(false)
    }

    if (driverId !== 'new') {
      fetchDriverData(driverId)
    } else {
      setLoadingDriver(false)
    }
  }, [driverId, user.companyId])

  useEffect(() => {
    const fetchAllVehicles = async () => {
      const vehiclesData = await fetchVehicles(user.companyId)
      setVehicles(vehiclesData)
    }

    fetchAllVehicles()
  }, [user.companyId])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleEditClick = async () => {
    if (isEditing) {
      if (driverId === 'new') {
        if (!driver?.name) {
          console.error('Name is required to create a new driver document.')
          return
        }
        setLoadingDriver(true)
        const newDriverId = await createDriver(user.companyId, driver)
        if (newDriverId) {
          setDriverId(newDriverId)
          setIsEditing(false)
          await updateVehiclesWithDriver(user.companyId, newDriverId, driver.name, selectedVehicles)
          navigate(`/drivers`)
        }
        setLoadingDriver(false)
      } else {
        setLoadingDriver(true)
        const success = await updateDriver(user.companyId, driverId, driver)
        if (success) {
          await updateVehiclesWithDriver(user.companyId, driverId, driver.name, selectedVehicles)
          navigate(`/drivers`)
        }
        setLoadingDriver(false)
      }
    } else {
      setIsEditing(true)
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setDriver({ ...driver, [name]: value })
  }

  const handleToggleShowVehicles = () => {
    setShowUnassignedOnly(!showUnassignedOnly)
  }

  const handleVehicleSelect = (vehicleId) => {
    setSelectedVehicles((prevState) =>
      prevState.includes(vehicleId) ? prevState.filter((id) => id !== vehicleId) : [...prevState, vehicleId]
    )
  }

  const filteredVehicles = vehicles.filter((vehicle) => {
    const unassignedFilter = showUnassignedOnly ? !vehicle.driverID : true
    const groupFilterCondition = groupFilter ? vehicle.groupID === groupFilter : true
    return unassignedFilter && groupFilterCondition
  })

  const uniqueGroups = [
    ...new Map(vehicles.map((vehicle) => [vehicle.groupID, { id: vehicle.groupID, name: vehicle.groupName }])).values(),
  ]

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <IconButton onClick={() => navigate('/drivers')}>
          <ArrowBackIcon />
        </IconButton>
        <Button variant="contained" color="primary" onClick={handleEditClick}>
          {isEditing ? 'Save' : 'Edit'}
        </Button>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={title} subtitle={subtitle} />
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider', minWidth: 200 }}
        >
          <Tab label="Basic Information" id={`vertical-tab-0`} aria-controls={`vertical-tabpanel-0`} />
          <Tab label="Vehicle Selection" id={`vertical-tab-1`} aria-controls={`vertical-tabpanel-1`} />
        </Tabs>
        <Box sx={{ flexGrow: 1, p: 3, bgcolor: 'background.paper' }}>
          <Box role="tabpanel" hidden={value !== 0} id={`vertical-tabpanel-0`} aria-labelledby={`vertical-tab-0`}>
            {value === 0 && (
              <>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <TextField
                    label="Name"
                    name="name"
                    value={driver?.name || ''}
                    onChange={handleInputChange}
                    InputProps={{ readOnly: !isEditing }}
                    variant="outlined"
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <TextField
                    label="Phone Number"
                    name="phone"
                    value={driver?.phone || ''}
                    onChange={handleInputChange}
                    InputProps={{ readOnly: !isEditing }}
                    variant="outlined"
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <TextField
                    label="Email (Optional)"
                    name="email"
                    value={driver?.email || ''}
                    onChange={handleInputChange}
                    InputProps={{ readOnly: !isEditing }}
                    variant="outlined"
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="languagepreference">Language Preference</InputLabel>
                  <Select
                    labelId="language-label"
                    id="language-select"
                    name="languagePreference"
                    value={driver?.languagePreference || 'en'}
                    label="languagepreference"
                    onChange={handleInputChange}
                    variant="outlined"
                    inputProps={{ readOnly: !isEditing }}
                    sx={{ height: 56 }}
                  >
                    {LanguagesPreferences.map((language) => (
                      <MenuItem key={language.code} value={language.code}>
                        {language.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
          </Box>
          <Box role="tabpanel" hidden={value !== 1} id={`vertical-tabpanel-1`} aria-labelledby={`vertical-tab-1`}>
            {value === 1 && (
              <>
                <Box display="flex" alignItems="center" mb={2}>
                  <Button variant="contained" color="primary" onClick={handleToggleShowVehicles}>
                    {showUnassignedOnly ? 'Show All Vehicles' : 'Show Unassigned Vehicles Only'}
                  </Button>
                  <FormControl sx={{ ml: 2 }} fullWidth>
                    <InputLabel id="group-filter-label">Filter by Group</InputLabel>
                    <Select
                      labelId="group-filter-label"
                      id="group-filter-select"
                      value={groupFilter}
                      onChange={(e) => setGroupFilter(e.target.value)}
                      variant="outlined"
                    >
                      <MenuItem value="">All Groups</MenuItem>
                      {uniqueGroups.map((group) => (
                        <MenuItem key={group.id} value={group.id}>
                          {group.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                {filteredVehicles.map((vehicle) => {
                  const { vinDecode = {} } = vehicle
                  const { Make = '', Model = '', ModelYear = '' } = vinDecode
                  return (
                    <Box key={vehicle.id} display="flex" alignItems="center">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedVehicles.includes(vehicle.id)}
                            onChange={() => handleVehicleSelect(vehicle.id)}
                            name={vehicle.id}
                            color="primary"
                            disabled={!isEditing}
                          />
                        }
                        label={`${vehicle.carName} (${Make} ${Model} ${ModelYear}) - VIN: ${vehicle.vin}`}
                      />
                    </Box>
                  )
                })}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default DriverEdit
