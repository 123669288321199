import React, { useState } from 'react'
import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material'
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined'
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import MenuOpenOutlined from '@mui/icons-material/MenuOpenOutlined'
import AccountMenu from './AccountMenu'
import { useColorMode } from '../../theme'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../../contexts/UserContext'

const Topbar = ({ isCollapsed, setIsCollapsed, setIsDrawerOpen, setSignInOpen }) => {
  const navigate = useNavigate()
  const { user } = useUser()
  const { theme, toggleColorMode } = useColorMode()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // Account Menu
  const [anchorEl, setAnchorEl] = useState(null)
  const handleOpenUserMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseUserMenu = () => {
    setAnchorEl(null)
  }

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* Collapsable Menu - Hidden on mobile */}
      {!isMobile && (
        <Box display="flex" borderRadius="3px">
          <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
            {isCollapsed ? <MenuOutlinedIcon /> : <MenuOpenOutlined />}
          </IconButton>
        </Box>
      )}

      {/* Drawer Menu - Shown only on mobile */}
      {isMobile && (
        <Box display="flex" borderRadius="3px">
          <IconButton onClick={() => setIsDrawerOpen(true)}>
            <MenuOutlinedIcon />
          </IconButton>
        </Box>
      )}

      {/* ICONS */}
      <Box display="flex">
        {/* <IconButton onClick={toggleColorMode}>
                    {theme.palette.mode === "dark" ? (
                        <LightModeOutlinedIcon />
                    ) : (
                        <DarkModeOutlinedIcon />
                    )}
                </IconButton> */}
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton onClick={() => navigate('/settings')}>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton onClick={handleOpenUserMenu}>
          <PersonOutlinedIcon />
        </IconButton>
        <AccountMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseUserMenu}
          setSignInOpen={setSignInOpen}
        />
      </Box>
    </Box>
  )
}

export default Topbar
