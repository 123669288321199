import { useState, useEffect } from 'react'
import { useColorMode } from '../../theme'
import {
  Grid,
  Box,
  IconButton,
  Tooltip,
  Button,
  Link,
  Typography,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import CustomDataGrid from '../../components/CustomDataGrid'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../../contexts/UserContext'
import { fetchGroups, updateVehicleGroup, deleteGroup } from '../../database/services/groups'
import { fetchVehicles } from '../../database/services/vehicles'

const GroupPlaces = () => {
  const { theme } = useColorMode()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [vehicles, setVehicles] = useState(null)
  const navigate = useNavigate()
  const { user } = useUser()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const groupsData = await fetchGroups(user.companyId)
        const vehiclesData = await fetchVehicles(user.companyId)
        setVehicles(vehiclesData)

        const groupsWithVehicleCount = groupsData.map((group) => {
          const groupVehicles = vehiclesData.filter((vehicle) => vehicle.groupID === group.id)
          return { ...group, numberOfVehicles: groupVehicles.length }
        })

        setRows(groupsWithVehicleCount)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching data: ', error)
        setLoading(false)
      }
    }

    fetchData()
  }, [user.companyId])

  const handleAddGroup = () => {
    navigate(`/edit-group/new`)
  }

  const handleEditClick = (row) => {
    navigate(`/edit-group/${row.id}`)
  }

  const handleDeleteClick = (row) => {
    setSelectedGroup(row)
    setDeleteDialogOpen(true)
  }

  const handleDeleteConfirm = async () => {
    if (selectedGroup) {
      try {
        const vehiclesToUpdate = vehicles.filter((vehicle) => vehicle.groupID === selectedGroup.id)
        const updatePromises = vehiclesToUpdate.map((vehicle) =>
          updateVehicleGroup(user.companyId, vehicle.id, null, null)
        )
        await Promise.all(updatePromises)

        await deleteGroup(user.companyId, selectedGroup.id)
        setRows(rows.filter((row) => row.id !== selectedGroup.id))
        setDeleteDialogOpen(false)
        setSelectedGroup(null)
      } catch (error) {
        console.error('Error deleting group: ', error)
      }
    }
  }

  const handleOrgClick = (orgName) => {
    navigate(`/organization/${orgName}`)
  }

  const columns = [
    { field: 'name', headerName: 'Name', width: 140 },
    { field: 'description', headerName: 'Description', width: 160 },
    { field: 'streetAddress1', headerName: 'Street Address', width: 120 },
    { field: 'city', headerName: 'City', width: 100 },
    { field: 'state', headerName: 'State', width: 100 },
    { field: 'numberOfVehicles', headerName: '# of Vehicles', width: 100 },
    {
      field: 'organizationName',
      headerName: 'Organization Legal Name',
      width: 150,
      renderCell: (params) =>
        params.value !== 'Same as administrator' ? (
          <Link component="button" variant="body2" onClick={() => handleOrgClick(params.value)}>
            {params.value}
          </Link>
        ) : (
          <span>{params.value}</span>
        ),
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 90,
      renderCell: (params) => (
        <Tooltip title="Edit Group">
          <IconButton onClick={() => handleEditClick(params.row)}>
            <EditOutlinedIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 90,
      renderCell: (params) => (
        <Tooltip title="Delete Group">
          <IconButton onClick={() => handleDeleteClick(params.row)}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ]

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomDataGrid rows={rows} columns={columns} pageSize={100} loading={loading} />
        </Grid>
        {isMobile && (
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: -1 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddGroup}
              sx={{
                height: '56px',
                minWidth: '100px',
                padding: '0 16px',
              }}
            >
              + GROUP
            </Button>
          </Grid>
        )}
      </Grid>
      <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete Group'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this group?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default GroupPlaces
