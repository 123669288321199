import { useState, useEffect } from 'react'
import Header from '../../components/Header'
import { useColorMode } from '../../theme'
import { Box, Tab } from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import AvaWeb from './avaWeb'
import AvaPDF from './avaPdf'
import AvaSMS from './avaSMS'
import AvaAuditor from './avaAuditor'
import { useNavigate, useLocation } from 'react-router-dom'

const Tools = () => {
  const { theme } = useColorMode()
  const navigate = useNavigate()
  const location = useLocation()
  const [value, setValue] = useState(location.pathname.split('/')[2] || 'avaauditor')

  const handleChange = (event, newValue) => {
    setValue(newValue)
    navigate(`/tools/${newValue}`)
  }

  useEffect(() => {
    setValue(location.pathname.split('/')[2] || 'avaauditor')
  }, [location])

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="TOOLS" subtitle="BETA" />
      </Box>
      <Box sx={{ width: '100%' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="Tools Tabs">
              <Tab label="AVA AUDITOR" value="avaauditor" />
              <Tab label="AVA PDF" value="avapdf" />
              <Tab label="AVA WEB" value="avaweb" />
              <Tab label="AVA SMS" value="avasms" />
            </TabList>
          </Box>
          <TabPanel value="avaauditor">
            <AvaAuditor />
          </TabPanel>
          <TabPanel value="avapdf">
            <AvaPDF />
          </TabPanel>
          <TabPanel value="avaweb">
            <AvaWeb />
          </TabPanel>
          <TabPanel value="avasms">
            <AvaSMS />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  )
}

export default Tools
