import { useState, useEffect } from 'react'
import { Grid, Box, Typography, Button, TextField } from '@mui/material'
import CustomDataGrid from '../../components/CustomDataGrid'
import { fetchAdminUsers, inviteAdminUser } from '../../database/services/users'

const columns = [{ field: 'email', headerName: 'Email', width: 300 }]

const FairwayAdmins = () => {
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [newEmail, setNewEmail] = useState('')

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const usersList = await fetchAdminUsers()
        setRows(usersList)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching data: ', error)
        setLoading(false)
      }
    }
    loadUsers()
  }, [])

  const handleInvite = async () => {
    try {
      const newRow = await inviteAdminUser(newEmail)
      setRows((prevRows) => [...prevRows, newRow])
      setNewEmail('')
    } catch (error) {
      console.error('Error inviting user: ', error)
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <CustomDataGrid
            rows={rows}
            columns={columns}
            pageSize={rows.length}
            pagination={false}
            loading={loading}
            autoHeight
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom>
            Super admins
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <TextField label="Email" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} fullWidth />
            <Button onClick={handleInvite} variant="contained" sx={{ ml: 2 }}>
              Add
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default FairwayAdmins
