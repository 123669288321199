import { useState, useEffect } from 'react'
import { useColorMode } from '../../theme'
import { Grid, Box, Typography, Button, TextField, IconButton, Tooltip } from '@mui/material'
import CustomDataGrid from '../../components/CustomDataGrid'
import { useUser } from '../../contexts/UserContext'
import { ContentCopy as CopyIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { useLocation } from 'react-router-dom'
import { fetchUsers, fetchInvitedUsers, inviteUser, deleteInvitedUser } from '../../database/services/users'

const columns = [
  { field: 'name', headerName: 'Name', width: 130 },
  { field: 'email', headerName: 'Email', width: 200 },
  { field: 'role', headerName: 'Role', width: 130 },
]

const ManageUsers = () => {
  const { theme } = useColorMode()
  const { user, company } = useUser()
  const [rows, setRows] = useState([])
  const [invitedEmails, setInvitedEmails] = useState([])
  const [loading, setLoading] = useState(true)
  const [newEmail, setNewEmail] = useState('')
  const location = useLocation()
  const [baseUrl, setBaseUrl] = useState('')

  useEffect(() => {
    const url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`
    setBaseUrl(url)
  }, [location])

  useEffect(() => {
    const fetchData = async () => {
      if (!company) return
      try {
        const usersList = await fetchUsers(company.id)
        setRows(usersList)

        const invitedUsersList = await fetchInvitedUsers(company.id)
        setInvitedEmails(invitedUsersList)

        setLoading(false)
      } catch (error) {
        console.error('Error fetching data: ', error)
        setLoading(false)
      }
    }

    fetchData()
  }, [company])

  const isEmailInvited = invitedEmails.some((invite) => invite.inviteEmail === newEmail)

  const handleInvite = async () => {
    try {
      const lowercasedNewEmail = newEmail.toLowerCase()
      const lowercasedUserEmail = user.email.toLowerCase()

      const inviteId = await inviteUser(company.id, company.name, lowercasedNewEmail, lowercasedUserEmail)

      const updatedInvitedEmails = [
        ...invitedEmails,
        {
          inviteId,
          inviteEmail: lowercasedNewEmail,
        },
      ]
      setInvitedEmails(updatedInvitedEmails)
      setNewEmail('')
    } catch (error) {
      console.error('Error inviting user: ', error)
    }
  }

  const handleInviteDelete = async (inviteEmail, inviteId) => {
    try {
      await deleteInvitedUser(company.id, inviteEmail, inviteId)
      const updatedInvitedEmails = invitedEmails.filter((invite) => invite.inviteEmail !== inviteEmail)
      setInvitedEmails(updatedInvitedEmails)
    } catch (error) {
      console.error('Error deleting invited user: ', error)
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <CustomDataGrid
            rows={rows}
            columns={columns}
            pageSize={rows.length}
            pagination={false}
            loading={loading}
            checkboxSelection
            autoHeight
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom>
            Invited Emails
          </Typography>
          {invitedEmails.length > 0 ? (
            invitedEmails.map((invite, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="body1">{invite.inviteEmail}</Typography>
                <Box>
                  <IconButton
                    onClick={() => navigator.clipboard.writeText(`${baseUrl}/companyInvite/${invite.inviteId}`)}
                    aria-label="copy"
                  >
                    <CopyIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleInviteDelete(invite.inviteEmail, invite.inviteId)}
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            ))
          ) : (
            <Typography variant="body1">No invited emails</Typography>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <TextField label="Email" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} fullWidth />
            <Tooltip title={isEmailInvited ? 'This email has an invitation pending' : ''} placement="bottom">
              <span>
                <Button onClick={handleInvite} variant="contained" sx={{ ml: 2 }} disabled={isEmailInvited}>
                  Invite
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ManageUsers
