import { useState, useEffect } from 'react'
import { Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import Header from '../../components/Header'
import { useColorMode } from '../../theme'
import {
  Box,
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Modal,
  Tooltip,
  Grid,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ErrorIcon from '@mui/icons-material/Error'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import { useUser } from '../../contexts/UserContext'
import { useNavigate, useLocation } from 'react-router-dom'
import FileUpload from '../global/FileUpload'
import { generateNewVehicle, vehicleSchema } from './vehicleUtils'
import { existingVehicle, fetchVehicleById, saveVehicleDocument } from '../../database/services/vehicles'
import { createDocument, subscribeToDocument } from '../../database/databaseUtils'

const Vehicle = () => {
  const { theme } = useColorMode()
  const { user } = useUser()
  const [value, setValue] = useState('1')
  const location = useLocation()
  const navigate = useNavigate()
  const [vehicleId, setVehicleId] = useState(location.pathname.split('/')[2] || null)
  const [vehicle, setVehicle] = useState(vehicleSchema)
  const [loadingVehicle, setLoadingVehicle] = useState(true)
  const [decoding, setDecoding] = useState(false)
  const [decodeError, setDecodeError] = useState(null)
  const [open, setOpen] = useState(false)
  const [results, setResults] = useState(null)
  const [vinEditDisable, setVinEditDisable] = useState(false)
  const [showSaveButton, setShowSaveButton] = useState(false)
  const [isVinValid, setIsVinValid] = useState(false)
  const [isVinDecoded, setIsVinDecoded] = useState(false) // New state to track if VIN has been decoded
  const [isNewVehicle, setIsNewVehicle] = useState(false) // New state to track if the vehicle is new

  useEffect(() => {
    if (vehicleId === 'new') {
      setVehicle({
        ...vehicleSchema,
      })
      setIsNewVehicle(true)
    } else {
      setIsNewVehicle(false)
    }
  }, [vehicleId])

  useEffect(() => {
    const fetchVehicle = async (vehicleId) => {
      try {
        const vehicleDoc = await fetchVehicleById(user.companyId, vehicleId)
        if (vehicleDoc) {
          setVehicle(vehicleDoc)
        }
      } catch (error) {
        console.error('Error fetching vehicle: ', error)
      } finally {
        setLoadingVehicle(false)
        setVinEditDisable(true)
      }
    }

    if (vehicleId !== 'new') {
      fetchVehicle(vehicleId)
    } else {
      setVehicle(generateNewVehicle())
      setLoadingVehicle(false)
    }
  }, [vehicleId, user.companyId])

  const saveVehicle = async (vehicleVin, vehicle) => {
    try {
      setLoadingVehicle(true)
      const vehicleDocId = vehicleId === 'new' ? vehicleVin : vehicleId // Determine document ID

      if (!vehicleDocId) {
        console.error('VIN is required to create or update the vehicle document.')
        return
      }

      console.log('Saving vehicle: ', vehicleDocId, vehicle)
      await saveVehicleDocument(user.companyId, vehicleDocId, vehicle)
    } catch (error) {
      console.error('Error saving vehicle information: ', error)
    } finally {
      setLoadingVehicle(false)
      setShowSaveButton(false)
      if (vehicleId === 'new') {
        setVehicleId(vehicle.vin.trim())
      }
    }
  }

  const handleSaveClick = async () => {
    saveVehicle(vehicleId, vehicle)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target

    const parsePath = (path) => {
      const parts = []
      const regex = /([^[.\]]+|\[(?:\d+|"[^"]*"|'[^']*'|[^"'\[\]]+)\])/g
      let match
      while ((match = regex.exec(path))) {
        parts.push(match[1].startsWith('[') ? match[1].slice(1, -1) : match[1])
      }
      return parts
    }

    const keys = parsePath(name)

    setVehicle((prevVehicle) => {
      const updateNestedField = (obj, keys) => {
        if (keys.length === 1) {
          return { ...obj, [keys[0]]: value }
        }

        const [firstKey, ...restKeys] = keys
        return {
          ...obj,
          [firstKey]: updateNestedField(obj[firstKey] || {}, restKeys),
        }
      }

      return updateNestedField(prevVehicle, keys)
    })

    if (name === 'vin') {
      setIsVinValid(value.length >= 17) // VINs are typically 17 characters long
    }

    setShowSaveButton(true)
  }

  // Files handlers
  const handleFileUpload = (field, { url, filename, docId }) => {
    setVehicle((prevVehicle) => ({
      ...prevVehicle,
      additional_user_input: {
        ...prevVehicle.additional_user_input,
        [field]: { url, filename, docId },
      },
    }))
    setShowSaveButton(true)
  }

  const handleFileDelete = (field) => {
    setVehicle((prevVehicle) => ({
      ...prevVehicle,
      additional_user_input: {
        ...prevVehicle.additional_user_input,
        [field]: null,
      },
    }))
    setShowSaveButton(true)
  }

  // List handlers
  const handleListChange = (event, section, key, index) => {
    const { value } = event.target
    setVehicle((prevVehicle) => {
      if (key) {
        // Nested case
        const updatedList = [...prevVehicle.openAI_additional_info[section][key]]
        updatedList[index] = value
        return {
          ...prevVehicle,
          openAI_additional_info: {
            ...prevVehicle.openAI_additional_info,
            [section]: {
              ...prevVehicle.openAI_additional_info[section],
              [key]: updatedList,
            },
          },
        }
      } else {
        // Non-nested case
        const updatedList = [...prevVehicle.openAI_additional_info[section]]
        updatedList[index] = value
        return {
          ...prevVehicle,
          openAI_additional_info: {
            ...prevVehicle.openAI_additional_info,
            [section]: updatedList,
          },
        }
      }
    })
    setShowSaveButton(true)
  }

  const handleAddItem = (section, key) => {
    setVehicle((prevVehicle) => {
      if (key) {
        // Nested case
        const updatedList = [...prevVehicle.openAI_additional_info[section][key], '']
        return {
          ...prevVehicle,
          openAI_additional_info: {
            ...prevVehicle.openAI_additional_info,
            [section]: {
              ...prevVehicle.openAI_additional_info[section],
              [key]: updatedList,
            },
          },
        }
      } else {
        // Non-nested case
        const updatedList = [...prevVehicle.openAI_additional_info[section], '']
        return {
          ...prevVehicle,
          openAI_additional_info: {
            ...prevVehicle.openAI_additional_info,
            [section]: updatedList,
          },
        }
      }
    })
    setShowSaveButton(true)
  }

  const handleRemoveItem = (section, key, index) => {
    setVehicle((prevVehicle) => {
      if (key) {
        // Nested case
        const updatedList = [...prevVehicle.openAI_additional_info[section][key]]
        updatedList.splice(index, 1)
        return {
          ...prevVehicle,
          openAI_additional_info: {
            ...prevVehicle.openAI_additional_info,
            [section]: {
              ...prevVehicle.openAI_additional_info[section],
              [key]: updatedList,
            },
          },
        }
      } else {
        // Non-nested case
        const updatedList = [...prevVehicle.openAI_additional_info[section]]
        updatedList.splice(index, 1)
        return {
          ...prevVehicle,
          openAI_additional_info: {
            ...prevVehicle.openAI_additional_info,
            [section]: updatedList,
          },
        }
      }
    })
    setShowSaveButton(true)
  }

  // Decode VIN process
  // decodeVIN button handle
  const decodeVIN = async () => {
    if (!vehicle?.vin) {
      console.error('VIN is required to decode the vehicle.')
      return
    }

    try {
      setDecoding(true)
      setDecodeError(null)

      // Check if a document already exists
      const existingDoc = await existingVehicle(user.companyId, vehicle.vin)
      if (existingDoc.exists()) {
        const data = existingDoc.data()
        if (data.status === 'success') {
          console.log('Decoded VIN results:', data.results)
          setResults(data.results)
          setOpen(true)
          setDecoding(false)
          setIsVinDecoded(true) // Set the state to true once VIN is successfully decoded
          return
        } else if (data.status === 'failed') {
          console.error('Error decoding VIN:', data.error)
          setDecodeError(data.error)
          setDecoding(false)
          return
        }
      }

      const docRefFile = {
        vin: vehicle.vin,
        status: 'new',
      }
      // Create a new document with a unique ID in the "decodeVIN" collection
      const collectionPath = ['customers', user.companyId, 'decodeVIN']
      const docRef = await createDocument(collectionPath, docRefFile)

      // Set up a Firestore listener on the newly created document
      const unsubscribe = subscribeToDocument(
        docRef,
        (doc) => {
          if (doc.exists()) {
            const data = doc.data()
            if (data.status === 'success') {
              console.log('Decoded VIN results:', data.results)
              setResults(data.results)
              setDecoding(false)
              setOpen(true)
              setIsVinDecoded(true) // Set the state to true once VIN is successfully decoded
              unsubscribe() // Stop listening once the results are received
            } else if (data.status === 'failed') {
              console.error('Error decoding VIN:', data.error)
              setDecoding(false)
              setDecodeError(data.error)
              unsubscribe() // Stop listening on error
            }
          } else {
            console.error('No such document!')
          }
        },
        (error) => {
          console.error('Error subscribing to document:', error)
          setDecodeError('Error initiating VIN decode. Please try again.')
          setDecoding(false)
        }
      )
      console.log('decodeVIN initiated')
    } catch (error) {
      console.error('Error initiating VIN decode: ', error)
      setDecodeError('Error initiating VIN decode. Please try again.')
      setDecoding(false)
    }
  }

  // Decode Modal close
  const handleClose = () => setOpen(false)

  // Decode Modal submit
  const handleSubmit = () => {
    const { carName, vinDecode, openAI_additional_info } = results
    const updatedVehicle = {
      ...vehicle,
      carName: carName,
      vinDecode: vinDecode,
      openAI_additional_info: openAI_additional_info,
    }
    setVehicle(updatedVehicle)
    saveVehicle(updatedVehicle.vin, updatedVehicle)
    setOpen(false)
  }

  return (
    <Box m="20px">
      {loadingVehicle ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Modal open={open} onClose={handleClose}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography variant="h6" component="h2">
                VIN Decoding Successful
              </Typography>
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Continue
              </Button>
            </Box>
          </Modal>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <IconButton onClick={() => navigate('/vehicles')}>
              <ArrowBackIcon />
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title="VEHICLE" subtitle={vehicleId === 'new' ? 'New vehicle' : `Vehicle data: ${vehicleId}`} />
          </Box>
          <Box display="flex" flexDirection="column">
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <FormControl sx={{ m: 1, width: 250 }}>
                <InputLabel htmlFor="component-outlined">License Plate</InputLabel>
                <OutlinedInput
                  id="component-outlined"
                  name="licensePlate"
                  value={vehicle?.licensePlate || ''}
                  onChange={handleInputChange}
                  label="License Plate"
                />
              </FormControl>
              <FormControl sx={{ m: 1, width: 400 }}>
                <InputLabel htmlFor="component-outlined">VIN</InputLabel>
                <OutlinedInput
                  id="component-outlined"
                  name="vin"
                  value={vehicle?.vin || ''}
                  onChange={handleInputChange}
                  label="VIN"
                  disabled={vinEditDisable}
                />
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                onClick={decodeVIN}
                sx={{ ml: 1, height: '56px', width: 130, padding: '0 16px' }}
                disabled={!isVinValid || decoding}
              >
                Decode VIN
              </Button>
              {decoding && <CircularProgress size={24} sx={{ ml: 1 }} />}
              {decodeError && (
                <Tooltip title={decodeError}>
                  <ErrorIcon color="error" sx={{ ml: 1 }} />
                </Tooltip>
              )}
            </Box>
            <Box>
              <FormControl sx={{ m: 1, width: 250 }}>
                <TextField
                  label="Year"
                  name="vinDecode.ModelYear"
                  value={vehicle?.vinDecode?.ModelYear || ''}
                  onChange={handleInputChange}
                  variant="outlined"
                />
              </FormControl>
              <FormControl sx={{ m: 1, width: 250 }}>
                <TextField
                  label="Make"
                  name="vinDecode.Make"
                  value={vehicle?.vinDecode?.Make || ''}
                  onChange={handleInputChange}
                  variant="outlined"
                />
              </FormControl>
              <FormControl sx={{ m: 1, width: 280 }}>
                <TextField
                  label="Model"
                  name="vinDecode.Model"
                  value={vehicle?.vinDecode?.Model || ''}
                  onChange={handleInputChange}
                  variant="outlined"
                />
              </FormControl>
            </Box>
            <Box>
              <FormControl sx={{ m: 1, width: 400 }}>
                <TextField
                  label="Car Name"
                  name="carName"
                  value={vehicle?.carName || ''}
                  onChange={handleInputChange}
                  variant="outlined"
                />
              </FormControl>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 1 }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Documentation" value="1" />
                  <Tab label="Important Information" value="2" />
                  <Tab label="Other" value="3" />
                </TabList>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <TabPanel value="1" sx={{ pl: 0 }}>
                  <Box display="flex" flexDirection="column">
                    <FormControl sx={{ m: 1, width: 800 }}>
                      <TextField
                        label="Vehicle registration expiration date"
                        name={`recommendedTasks.startRegistrationRenewal.nextRenewalDate`}
                        type="date"
                        value={vehicle?.recommendedTasks?.startRegistrationRenewal?.nextRenewalDate || ''}
                        onChange={handleInputChange}
                        variant="outlined"
                        sx={{ width: '80%' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                    <Typography variant="h7" sx={{ m: 1, mt: 2 }}>
                      Upload Important Documents
                    </Typography>
                    <FormControl sx={{ m: 1, width: 800 }}>
                      <TextField
                        label="Important Document"
                        name={`additional_user_input.[Important Document]`}
                        value={vehicle?.additional_user_input?.['Important Document']?.filename || ''}
                        onChange={handleInputChange}
                        variant="outlined"
                        sx={{ width: '80%' }}
                        InputProps={{
                          readOnly: true,
                          endAdornment: vehicle?.additional_user_input?.['Important Document']?.url ? (
                            <>
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  window.open(vehicle.additional_user_input['Important Document'].url, '_blank')
                                }
                                sx={{ m: 1 }}
                              >
                                <VisibilityIcon />
                              </IconButton>
                              <IconButton
                                color="error"
                                onClick={() => handleFileDelete('Important Document')}
                                sx={{ m: 1 }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </>
                          ) : (
                            <FileUpload
                              onSuccess={({ url, filename, docId }) =>
                                handleFileUpload('Important Document', {
                                  url,
                                  filename,
                                  docId,
                                })
                              }
                            />
                          ),
                        }}
                      />
                    </FormControl>
                  </Box>
                </TabPanel>
                <TabPanel value="2" sx={{ pl: 0 }}>
                  <Typography variant="h7" sx={{ m: 1, mb: 2 }}>
                    Specifications
                  </Typography>
                  <Box display="flex" flexDirection="column" sx={{ mt: 1, mb: 1 }}>
                    <FormControl sx={{ m: 1, minWidth: 400 }}>
                      <TextField
                        label="Manufacturer Name"
                        name="vinDecode.Manufacturer"
                        value={vehicle?.vinDecode?.Manufacturer || ''}
                        onChange={handleInputChange}
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 400 }}>
                      <TextField
                        label="Vehicle Type"
                        name="vinDecode.VehicleType"
                        value={vehicle?.vinDecode?.VehicleType || ''}
                        onChange={handleInputChange}
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                      <TextField
                        label="Weight"
                        name="openAI_additional_info.Vehicle Specifications.Weight"
                        value={vehicle?.openAI_additional_info?.['Vehicle Specifications']?.Weight || ''}
                        onChange={handleInputChange}
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                      <TextField
                        label="Gross Vehicle Weight Rating"
                        name="vinDecode.GVWR"
                        value={vehicle?.vinDecode?.GVWR || ''}
                        onChange={handleInputChange}
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                      <TextField
                        label="Fuel Type"
                        name="vinDecode.FuelTypePrimary"
                        value={vehicle?.vinDecode?.FuelTypePrimary || ''}
                        onChange={handleInputChange}
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                      <TextField
                        label="Fuel Tank Capacity"
                        name="openAI_additional_info.Vehicle Specifications.Fuel Tank Capacity"
                        value={
                          vehicle?.openAI_additional_info?.['Vehicle Specifications']?.['Fuel Tank Capacity'] || ''
                        }
                        onChange={handleInputChange}
                        variant="outlined"
                      />
                    </FormControl>
                    <Typography variant="h7" sx={{ mt: 1, m: 1, mb: 1 }}>
                      Fuel Efficiency
                    </Typography>
                    <Grid container spacing={2} sx={{ mt: 1, mb: 1, mr: 1 }}>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                          <TextField
                            label="City"
                            name="openAI_additional_info.Fuel Efficiency.City"
                            value={vehicle?.openAI_additional_info?.['Fuel Efficiency']?.City || ''}
                            onChange={handleInputChange}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                          <TextField
                            label="Combined"
                            name="openAI_additional_info.Fuel Efficiency.Combined"
                            value={vehicle?.openAI_additional_info?.['Fuel Efficiency']?.Combined || ''}
                            onChange={handleInputChange}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                          <TextField
                            label="Highway"
                            name="openAI_additional_info.Fuel Efficiency.Highway"
                            value={vehicle?.openAI_additional_info?.['Fuel Efficiency']?.Highway || ''}
                            onChange={handleInputChange}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box display="flex" flexDirection="column" sx={{ mt: 1, mb: 1 }}>
                    <Typography variant="h7" sx={{ m: 1, mb: 2 }}>
                      Value
                    </Typography>
                    <FormControl sx={{ m: 1, minWidth: 400 }}>
                      <TextField
                        label="Average Resale Value"
                        name="openAI_additional_info.Average Value.Used"
                        value={vehicle?.openAI_additional_info?.['Average Value']?.['Used'] || ''}
                        onChange={handleInputChange}
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 400 }}>
                      <TextField
                        label="Average Maintenance Cost"
                        name="openAI_additional_info.Average Maintenance Cost"
                        value={vehicle?.openAI_additional_info?.['Average Maintenance Cost'] || ''}
                        onChange={handleInputChange}
                        variant="outlined"
                      />
                    </FormControl>
                    <Box display="flex" flexDirection="column" sx={{ mt: 1, mb: 2, mr: 1 }}>
                      <Typography variant="h7" sx={{ m: 1, mb: 2 }}>
                        Recommended Maintenance
                      </Typography>
                      {(vehicle.openAI_additional_info?.['Recommended Maintenance'] || []).map((item, index) => (
                        <Box key={index} display="flex" alignItems="center" sx={{ mb: 1 }}>
                          <TextField
                            name={`Recommended Maintenance_${index}`}
                            value={item || ''}
                            onChange={(e) => handleListChange(e, 'Recommended Maintenance', null, index)}
                            variant="outlined"
                            sx={{ flexGrow: 1 }}
                          />
                          <IconButton onClick={() => handleRemoveItem('Recommended Maintenance', null, index)}>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      ))}
                      <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                        <IconButton onClick={() => handleAddItem('Recommended Maintenance', null)}>
                          <AddIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel value="3" sx={{ pl: 0 }}>
                  <Box display="flex" flexDirection="column" sx={{ mt: 1, mb: 1, mr: 1 }}>
                    <Typography variant="h9" sx={{ m: 1, mb: 2 }}>
                      Strengths
                    </Typography>
                    {(vehicle?.openAI_additional_info?.['Known Problems and Strengths']?.Strengths || []).map(
                      (item, index) => (
                        <Box key={index} display="flex" alignItems="center" sx={{ mb: 1 }}>
                          <TextField
                            name={`openAI_additional_info.Known Problems and Strengths.Strengths.${index}`}
                            value={item || ''}
                            onChange={(e) => handleListChange(e, 'Known Problems and Strengths', 'Strengths', index)}
                            variant="outlined"
                            sx={{ flexGrow: 1 }}
                          />
                          <IconButton
                            onClick={() => handleRemoveItem('Known Problems and Strengths', 'Strengths', index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      )
                    )}
                    <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                      <IconButton onClick={() => handleAddItem('Known Problems and Strengths', 'Strengths')}>
                        <AddIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="column" sx={{ mt: 1, mb: 1, mr: 1 }}>
                    <Typography variant="h9" sx={{ m: 1, mb: 2 }}>
                      Known Problems
                    </Typography>
                    {(vehicle?.openAI_additional_info?.['Known Problems and Strengths']?.Problems || []).map(
                      (item, index) => (
                        <Box key={index} display="flex" alignItems="center" sx={{ mb: 1 }}>
                          <TextField
                            name={`openAI_additional_info.Known Problems and Strengths.Problems.${index}`}
                            value={item || ''}
                            onChange={(e) => handleListChange(e, 'Known Problems and Strengths', 'Problems', index)}
                            variant="outlined"
                            sx={{ flexGrow: 1 }}
                          />
                          <IconButton
                            onClick={() => handleRemoveItem('Known Problems and Strengths', 'Problems', index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      )
                    )}
                    <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                      <IconButton onClick={() => handleAddItem('Known Problems and Strengths', 'Problems')}>
                        <AddIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  <Typography variant="h9" sx={{ m: 1, mb: 2 }}>
                    Other Information
                  </Typography>
                  <Grid container spacing={2} sx={{ mt: 1, mb: 1, mr: 1 }}>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          label="Engine Manufacturer"
                          name="vinDecode.EngineManufacturer"
                          value={vehicle?.vinDecode?.EngineManufacturer || ''}
                          onChange={handleInputChange}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          label="Engine Model"
                          name="vinDecode.EngineModel"
                          value={vehicle?.vinDecode?.['EngineModel'] || ''}
                          onChange={handleInputChange}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          label="Engine Size"
                          name="openAI_additional_info.Vehicle Specifications.Engine Size"
                          value={vehicle?.openAI_additional_info?.['Vehicle Specifications']?.['Engine Size'] || ''}
                          onChange={handleInputChange}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          label="Engine HP"
                          name="vinDecode.EngineHP"
                          value={vehicle?.vinDecode?.EngineHP || ''}
                          onChange={handleInputChange}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          label="Drivetrain"
                          name="openAI_additional_info.Vehicle Specifications.Drivetrain"
                          value={vehicle?.openAI_additional_info?.['Vehicle Specifications']?.Drivetrain || ''}
                          onChange={handleInputChange}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          label="Towing Capacity"
                          name="openAI_additional_info.Vehicle Specifications.Towing Capacity"
                          value={vehicle?.openAI_additional_info?.['Vehicle Specifications']?.['Towing Capacity'] || ''}
                          onChange={handleInputChange}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          label="Transmission Type"
                          name="openAI_additional_info.Vehicle Specifications.Transmission Type"
                          value={
                            vehicle?.openAI_additional_info?.['Vehicle Specifications']?.['Transmission Type'] || ''
                          }
                          onChange={handleInputChange}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          label="BodyCab Type"
                          name="vinDecode.BodyCabType"
                          value={vehicle?.vinDecode?.BodyCabType || ''}
                          onChange={handleInputChange}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          label="BodyClass"
                          name="vinDecode.BodyClass"
                          value={vehicle?.vinDecode?.BodyClass || ''}
                          onChange={handleInputChange}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </TabPanel>
              </Box>
            </TabContext>
          </Box>
          <Box display="flex" sx={{ m: 1 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveClick}
              sx={{
                ml: 0,
                height: '56px',
                minWidth: '100px',
                padding: '0 16px',
              }}
              disabled={isNewVehicle ? !isVinDecoded : !showSaveButton} // Conditional disable based on new or existing vehicle
            >
              Save
            </Button>
          </Box>
        </>
      )}
    </Box>
  )
}

export default Vehicle
