import { Box, Typography } from '@mui/material'
import { Menu, MenuItem } from 'react-pro-sidebar'
import { Link } from 'react-router-dom'
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined'
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined'
import ConstructionIcon from '@mui/icons-material/Construction'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import { useColorMode } from '../../theme'
import { useUser } from '../../contexts/UserContext'

const Item = ({ title, to, icon, selected, setSelected, setIsDrawerOpen }) => {
  const { theme } = useColorMode()

  const handleClick = () => {
    setSelected(title)
    if (setIsDrawerOpen) {
      setIsDrawerOpen(false)
    }
  }

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: theme.palette.text.secondary,
      }}
      onClick={handleClick}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  )
}

const SidebarContent = ({ isCollapsed, selected, setSelected, setIsDrawerOpen }) => {
  const { theme } = useColorMode()
  const { user } = useUser()

  // Check if the user is a superAdmin and email ends with @getfairway.com
  const isFairwayAdmin = user?.superAdmin && user?.email?.endsWith('@getfairway.com')

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Menu iconShape="square" style={{ flexGrow: 1 }}>
        {/* Profile Section */}
        <Box paddingLeft={isCollapsed ? '4%' : '16%'} m="25px">
          <Box display="flex">
            {isCollapsed ? (
              <Box mr="10px">
                <img
                  alt="profile-user"
                  width="40px"
                  height="40px"
                  src={`/lZXKi55cQFwf.png`}
                  style={theme.palette.mode === 'light' ? { filter: 'brightness(0.3) contrast(1.2)' } : {}}
                />
              </Box>
            ) : (
              <Box mr="50px" display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                <img
                  alt="profile-user"
                  width="120"
                  height="120"
                  src={`/Fwufh6cTORu5.png`}
                  style={theme.palette.mode === 'light' ? { filter: 'brightness(0.3) contrast(1.2)' } : {}}
                />
              </Box>
            )}
          </Box>
        </Box>

        {/* Menu Items */}
        <Box paddingLeft={isCollapsed ? '10%' : '5%'}>
          <Item
            title="Tools"
            to="/tools"
            icon={<ConstructionIcon />}
            selected={selected}
            setSelected={setSelected}
            setIsDrawerOpen={setIsDrawerOpen}
          />
          <Item
            title="Compliance"
            to="/compliance"
            icon={<DirectionsCarOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
            setIsDrawerOpen={setIsDrawerOpen}
          />
          <Item
            title="Dashboard"
            to="/dashboard"
            icon={<BarChartOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
            setIsDrawerOpen={setIsDrawerOpen}
          />
        </Box>
      </Menu>

      {/* Fairway Admin Section */}
      {isFairwayAdmin && (
        <Menu iconShape="square" style={{ marginTop: 'auto' }}>
          <Item
            title="Fairway Admin"
            to="/superadmin"
            icon={<AdminPanelSettingsOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
            setIsDrawerOpen={setIsDrawerOpen}
          />
        </Menu>
      )}
    </Box>
  )
}

export default SidebarContent
