import { useTheme } from '@mui/material'
import { ResponsivePie } from '@nivo/pie'
import { useColorMode } from '../theme'

const PieChart = ({ data = {}, isDashboard = false }) => {
  const { theme } = useColorMode()

  return (
    <ResponsivePie
      data={data}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: theme.palette.text.secondary,
            },
          },
          legend: {
            text: {
              fill: theme.palette.text.secondary,
            },
          },
          ticks: {
            line: {
              stroke: theme.palette.text.secondary,
              strokeWidth: 1,
            },
            text: {
              fill: theme.palette.text.secondary,
            },
          },
        },
        legends: {
          text: {
            fill: theme.palette.text.secondary,
          },
        },
        tooltip: {
          container: {
            color: '#000',
          },
        },
      }}
      margin={{ top: 18, right: 100, bottom: 30, left: 100 }}
      valueFormat=">-.3s"
      innerRadius={0.55}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      colors={({ id, data }) => data[`color`]}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor={theme.palette.text.secondary}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLinkLabelsOffset={-15} // Adjusted offset to keep labels inside the box
      arcLabelsTextColor={{
        from: 'color',
        modifiers: [['darker', 2]],
      }}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      legends={[]}
    />
  )
}

export default PieChart
