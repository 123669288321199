import { collection, getDocs, query, orderBy, updateDoc, doc } from 'firebase/firestore'
import { db } from '../../firebaseConfig'

export const fetchTasks = async (companyId) => {
  try {
    const querySnapshot = await getDocs(
      query(collection(db, 'customers', companyId, 'tasks'), orderBy('dateStarted', 'desc'))
    )
    return querySnapshot.docs.map((doc) => {
      const data = doc.data()
      const dateStarted = data.dateStarted instanceof Date ? data.dateStarted.toLocaleDateString() : data.dateStarted
      return { id: doc.id, ...data, dateStarted: dateStarted }
    })
  } catch (error) {
    console.error('Error fetching tasks: ', error)
    throw error
  }
}

export const cancelTask = async (companyId, taskId) => {
  try {
    const taskDocRef = doc(db, 'customers', companyId, 'tasks', taskId)
    await updateDoc(taskDocRef, {
      status: { dotColor: 'red', text: 'Cancelled' },
    })
    return { success: true }
  } catch (error) {
    console.error('Error cancelling task: ', error)
    return { success: false, error }
  }
}

export const submitFeedback = async (companyId, taskId, feedback) => {
  try {
    const taskDocRef = doc(db, 'customers', companyId, 'tasks', taskId)
    await updateDoc(taskDocRef, { feedback })
    return { success: true }
  } catch (error) {
    console.error('Error submitting feedback: ', error)
    return { success: false, error }
  }
}
