import React, { useContext, useState, useEffect, createContext, useMemo } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { useLocation } from 'react-router-dom'
import {
  fetchInviteData,
  createUser,
  getNewCompanyInvite,
  createCompany,
  linkUserToCompany,
  getUserCompanyRole,
  isSuperAdmin,
} from './helpers'
import { fetchUserData, fetchCompanyData, getAuthInstance } from '../database/services/users'

const defaultUserContext = {
  user: null,
  company: null,
}

const UserContext = createContext(defaultUserContext)

export const useUser = () => useContext(UserContext)

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const setToStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}

const getFromStorage = (key) => {
  const value = localStorage.getItem(key)
  try {
    return value ? JSON.parse(value) : null
  } catch (error) {
    return null
  }
}

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(getFromStorage('user'))
  const [userError, setUserError] = useState(false)
  const [invitation, setInvitation] = useState(null)
  const [company, setCompany] = useState(getFromStorage('company'))
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [authUserData, setAuthUserData] = useState(null)
  const [loadingUser, setLoadingUser] = useState(true)
  const [loadingUserContext, setLoadingUserContext] = useState(true)
  const [loadingUserInviteData, setLoadingUserInviteData] = useState(true)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const location = useLocation()
  const [backendUrl, setBackendUrl] = useState('')

  // BackendUrl
  useEffect(() => {
    let url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`
    if (url.includes(':3000')) {
      url = url.replace(':3000', ':5000')
      url = url.replace('localhost', '127.0.0.1')
    } else {
      // Dev environment: change URL to point to production backend
      url = process.env.REACT_APP_BACKEND_URL
    }
    setBackendUrl(url)
  }, [location])

  // Invitation state from path
  useEffect(() => {
    fetchInviteData(location, setInvitation, setLoadingUserInviteData)
  }, [])

  // Invitation state from path

  const loadUserToContext = async () => {
    setLoadingUser(true)
    setLoadingUserContext(true)
    setIsLoggedIn(true)

    // Fetch user data
    const userDoc = await fetchUserData(authUserData.uid)
    const userExists = userDoc !== null
    let userData
    let companyId

    if (userExists) {
      userData = userDoc
    } else {
      userData = await createUser(authUserData)
    }

    if (invitation) {
      if (invitation?.type === 'companyOnboarding') {
        const newCompanyInvite = await getNewCompanyInvite(userData.email)
        if (newCompanyInvite) {
          const companyName = newCompanyInvite.companyName
          companyId = await createCompany(userData, companyName)
          userData.companyId = companyId
          userData.role = 'admin'
        } else {
          setUserError(true)
        }
      } else if (invitation?.type === 'companyInvite') {
        await linkUserToCompany(userData, invitation, 'user')
      } else if (invitation?.type === 'signInLinkSent') {
        // Do nothing
      } else {
        setUserError(true)
      }
      // No invitation data
    } else {
      console.log('No invitation')
      if (userData.companyId) {
        companyId = userData.companyId
        // Assign and get role in context
        userData.role = await getUserCompanyRole(companyId, userData.uid)
      } else {
        // User has no invitation and no assigned company
        setUserError(true)
      }
    }

    if (!userData?.role && userData?.companyId) {
      userData.role = await getUserCompanyRole(userData.companyId, userData.uid)
    }
    userData.superAdmin = await isSuperAdmin(userData.email)
    console.log('user: ', userData)
    setUser(userData)
    setToStorage('user', userData)
    setLoadingUser(false)
    setLoadingUserContext(false)
  }

  // Auhentication
  useEffect(() => {
    const auth = getAuthInstance()
    const unsubscribe = onAuthStateChanged(auth, async (authStateChangeData) => {
      setLoadingUserContext(true)
      if (authStateChangeData) {
        setAuthUserData(authStateChangeData)
      } else {
        setUserError(false)
        setUser(null)
        setCompany(null)
        setIsLoggedIn(false)
        localStorage.removeItem('user')
        localStorage.removeItem('company')
      }
      setLoadingUserContext(false)
    })
    return () => unsubscribe()
  }, [])

  // User state load
  useEffect(() => {
    if (authUserData && invitation?.type !== 'signInLinkSent') {
      loadUserToContext()
    }
  }, [authUserData, invitation])

  // Fetch company data if user has a companyId
  useEffect(() => {
    const fetchCompanyDataAsync = async function () {
      if (user?.companyId) {
        const companyDoc = await fetchCompanyData(user.companyId)
        if (companyDoc) {
          const companyData = { ...companyDoc, id: user.companyId }
          setCompany(companyData)
          setToStorage('company', companyData)
        }
      }
    }
    if (!loadingUser) {
      fetchCompanyDataAsync()
    }
  }, [user?.companyId, loadingUser])

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackbar(false)
  }

  const value = useMemo(
    () => ({
      backendUrl,
      user,
      setUser,
      company,
      setCompany,
      userError,
      invitation,
      setInvitation,
      loadingUserContext,
      loadingUserInviteData,
      isLoggedIn,
    }),
    [
      backendUrl,
      user,
      setUser,
      company,
      setCompany,
      userError,
      invitation,
      setInvitation,
      loadingUserContext,
      loadingUserInviteData,
      isLoggedIn,
    ]
  )

  return (
    <UserContext.Provider value={value}>
      {children}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ top: { xs: '20px', md: '20px' } }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Hello {user?.name || 'there'}!
        </Alert>
      </Snackbar>
    </UserContext.Provider>
  )
}
