import { useState, useEffect } from 'react'
import Header from '../../components/Header'
import { useColorMode } from '../../theme'
import { Box, Typography, Breadcrumbs, Link, Tab, Button, useMediaQuery } from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Compliance from './Compliance'
import AutomatedTasks from './AutomatedTasks'
import GroupPlaces from './GroupPlaces'
import Drivers from './Drivers'
import { useNavigate, useLocation } from 'react-router-dom'
import { useUser } from '../../contexts/UserContext'

const Vehicles = () => {
  const { theme } = useColorMode()
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useUser()
  const isAdmin = user && user.role === 'admin'
  const [value, setValue] = useState(location.pathname.split('/')[2] || 'compliance')
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleChange = (event, newValue) => {
    setValue(newValue)
    navigate(`/vehicles/${newValue}`)
  }

  useEffect(() => {
    setValue(location.pathname.split('/')[2] || 'compliance')
  }, [location])

  const handleAddVehicle = () => {
    navigate(`/vehicle/new`)
  }

  const handleAddGroup = () => {
    navigate(`/edit-group/new`)
  }

  const handleAddDriver = () => {
    navigate(`/edit-driver/new`)
  }

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="VEHICLE COMPLIANCE" />
      </Box>
      <Box sx={{ width: '100%', position: 'relative' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChange}
              aria-label="Vehicles Tabs"
              variant="scrollable"
              scrollButtons="auto" // 'auto' will display scroll buttons only when needed
              allowScrollButtonsMobile // This prop makes scroll buttons available on mobile devices as well
            >
              <Tab label="Vehicles Compliance" value="compliance" />
              <Tab label="Automated Tasks" value="automated-tasks" />
              <Tab label="Groups & Places" value="groups-places" />
              <Tab label="Drivers" value="drivers" />
              <Tab label="Archived Vehicles" value="archived" />
            </TabList>
          </Box>
          <TabPanel value="compliance" sx={{ p: { xs: 1, sm: 2 } }}>
            <Compliance />
          </TabPanel>
          <TabPanel value="automated-tasks">
            <AutomatedTasks />
          </TabPanel>
          <TabPanel value="groups-places">
            <GroupPlaces /> {/* New tab panel */}
          </TabPanel>
          <TabPanel value="drivers">
            <Drivers /> {/* New tab panel */}
          </TabPanel>
          <TabPanel value="archived">
            <Compliance />
          </TabPanel>
        </TabContext>
        {!isMobile && (
          <>
            {['compliance', 'archived'].includes(value) && (
              <Box sx={{ position: 'absolute', top: '5px', right: '10px' }}>
                <Button onClick={handleAddVehicle} variant="contained" color="primary">
                  + Vehicle
                </Button>
              </Box>
            )}
            {value === 'groups-places' && (
              <Box sx={{ position: 'absolute', top: '5px', right: '10px' }}>
                <Button onClick={handleAddGroup} variant="contained" color="primary">
                  + Group
                </Button>
              </Box>
            )}
            {value === 'drivers' && (
              <Box sx={{ position: 'absolute', top: '5px', right: '10px' }}>
                <Button onClick={handleAddDriver} variant="contained" color="primary">
                  + Driver
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

export default Vehicles
