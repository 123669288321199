import React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Box } from '@mui/material'
import { useColorMode } from '../theme'

const CustomDataGrid = ({
  rows,
  columns,
  pageSize = 100,
  loading = false,
  autoHeight = true,
  disableRowSelectionOnClick = true,
  ...props
}) => {
  const { theme } = useColorMode()

  return (
    <Box
      sx={{
        width: '100%',
        overflowX: 'auto',
        overflowY: 'auto',
        paddingBottom: '0px',
        '&::-webkit-scrollbar': {
          width: '12px',
          height: '12px',
        },
        '&::-webkit-scrollbar-track': {
          background: theme.palette.background.secondary,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.background.primary,
          borderRadius: '10px',
          border: `3px solid ${theme.palette.background.secondary}`,
        },
        '& *': {
          scrollbarWidth: 'thin',
          scrollbarColor: `${theme.palette.background.primary} ${theme.palette.background.secondary}`,
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        loading={loading}
        autoHeight={autoHeight}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        sx={{
          '& .MuiDataGrid-row': {
            minHeight: '50px',
          },
          // Add any additional global styles here
        }}
        {...props}
      />
    </Box>
  )
}

export default CustomDataGrid
